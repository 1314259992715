<div [class.high-contrast]="highContrast" class="app-container">
  <app-header></app-header>
  <div class="content-wrapper">
    <div class="d-flex align-items-center justify-content-end margin-right-5P margin-top-1P position-absolute end-0 cursor-pointer" *ngIf="!isHomeRoute">
      <img [attr.src]="highContrast ? 'assets/backArrowBlack.svg' : 'assets/backArrowBlue.svg'" class="me-2"  (click)="goToHome()" >
      <span class="ml-2 blue-color" (click)="goToHome()" >Back</span>
    </div>
    <router-outlet></router-outlet>
    <div class="container d-flex justify-content-between flex-wrap  mb-3">
      <span class="footer-left span-xs span-s span-md">
        <app-contrast-switch (highContrastChange)="onHighContrastChange($event)"></app-contrast-switch>
      </span>
      <span class="footer-right span-xs span-s span-md">
        <div id="changeLocation" class="display-contents fw-medium" *ngIf="!isChangeLocationRoute">
          <a routerLink="/location">Change Location</a>
        </div>
      </span>
    </div>
  </div>
  <app-footer></app-footer>
</div>
