import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UnitService } from '../../services/unit/unit.service';
import { CommonService } from 'src/app/services/common/common.service';
import { ConfirmationService } from 'primeng/api';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FUEL_LEVEL_ELECTRIC, FUEL_LEVEL_NON_ELECTRIC } from '../../constants/fuelLevels';
import { InspectionUtilsService } from '../../services/inspection-utils/inspection-utils.service';
import { inspectionType } from '../../interfaces/inspectionType';
import { INSEPECTION_COMPLETE_MSG } from '../../constants/alert-messages';
import { CommonModule } from '@angular/common';
import { AlertComponent } from '../alert/alert.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LoaderComponent } from '../loader/loader.component';
import { FloatLabelModule } from 'primeng/floatlabel';
import { ScreenSizeService } from 'src/app/services/screen-size/screen-size.service';
import { InputTextModule } from 'primeng/inputtext';


@Component({
  selector: 'app-unit-search',
  standalone: true,
  imports: [CommonModule, AlertComponent, ReactiveFormsModule, FormsModule, ConfirmDialogModule, LoaderComponent, FloatLabelModule, InputTextModule],
  templateUrl: './unit-search.component.html',
  styleUrls: ['./unit-search.component.scss'],
  providers: [ConfirmationService]
})
export class UnitSearchComponent implements OnInit {
  unitNumber: string = '';
  customerEmail: string = '';
  unitNotFound: boolean = false;
  unitFoundNoRA: boolean = false;
  changeRA: boolean = false;
  showCheckout: boolean = false;
  showCheckIn: boolean = false;
  showDuplicateReportDialog: boolean = false;
  checkInOutForm!: FormGroup;
  locationDifferent: boolean = false;
  showLocationAlert:boolean = false;
  dynamicWarningMessage: string = '';
  showLoader: boolean = false;
  noRAClicked:boolean = false;
  latestreport:any = {};
  isVehicleTypeElectric:boolean = false;
  fuelLevelObj:any = {
    value:'',
    fuelLevelElectric: FUEL_LEVEL_ELECTRIC,
    fuelLevelNonElectric: FUEL_LEVEL_NON_ELECTRIC,
  };
  raByUnitData:any={};
  newRAObjectData:any={};
  manualRA:any={};
  raNumber: any;
  inspectionTabs!:inspectionType
  inspectionCompleteMsg:string = INSEPECTION_COMPLETE_MSG;
  isNumAndLetter: boolean = false;
  isTabletOrBigger$:any;

  constructor(private router: Router, private unitService: UnitService, public commonService: CommonService,
    private confirmationService: ConfirmationService, private fb: FormBuilder, public screenSizeService: ScreenSizeService,
    public inspectionUtilsService: InspectionUtilsService) { }

  ngOnInit(): void {
    this.checkInOutForm = this.fb.group({
      customerNotPresent: [false],
      signingCustomer: ['', Validators.required],
      emailAddress: ['', [Validators.email]],
      fuel: ['', Validators.required],
      mileageOut: ['', Validators.required],
      mileageIn: ['', Validators.required],
      furniturePads: ['', Validators.required],
      handtrucks: ['', Validators.required],
      handtrucksReturned: ['', Validators.required],
      furniturePadsReturned: ['', Validators.required],
      reeferStandbyHrs: [''],
      reeferRunningHrs: ['']

    });

    this.screenSizeService.isTabletOrBigger().subscribe({
      next:isTablet=>{
        this.isTabletOrBigger$ = isTablet;
      }
    });

    this.inspectionTabs = new inspectionType();
    if(this.commonService.isInspectionComplete){
      this.handleInspectionComplete();
    }
    this.resetHomeWhenPenskeLogoClicked();
    if(this.commonService.getFinalReportData()?.unitNumber){
      this.raByUnitData = this.commonService.raByUnitData;
      this.manualRA = this.commonService.manualRAObjectData;
      this.newRAObjectData = this.commonService.getFinalReportData();
      this.newRAObjectData.vehicleInfo = {};
      this.newRAObjectData.vehicleInfo[this.newRAObjectData.unitNumber] = this.raByUnitData.vehicleValidation;
      this.unitNumber = this.raByUnitData.vehicleValidation.unitNumber;
      this.newRAObjectData.inspectionType == 'IN' ? this.showCheckIn = true : this.showCheckout = true;
      this.handleCheckInAndCheckOut(this.newRAObjectData.inspectionType);
    }else{
      this.setRAObjects();
    }
  }

  isOutboundFPQty() {
    return this.showCheckIn && this.checkInOutForm.controls['furniturePadsReturned']?.value > this.checkInOutForm.controls['furniturePads']?.value
  }

  isOutboundHTQty() {
    return this.showCheckIn && this.checkInOutForm.controls['handtrucksReturned']?.value > this.checkInOutForm.controls['handtrucks']?.value
  }

  setRAObjects(){
    this.newRAObjectData = this.commonService.newRaObject('OUT');
    this.manualRA = this.commonService.manualRAObject();
  }

  onCheckInOutClick(type: string) {
    this.showLoader = true;
    this.checkInOutForm.reset();

    this.resetInspectionReportDetails();

    this.unitService.getLatestReport(this.unitNumber).subscribe({
        next:(resp:any)=>{
          if (resp && resp.success && resp?.data?.raNumber != null) {
            this.showLoader = false;
            this.showDuplicateReportDialog = true;
            this.inspectionUtilsService.latestReportData = resp.data;
            this.confirmationService.confirm({
              header: 'Duplicate Report Warning',
              message: 'This report has already been completed and signed. Are you sure you want to make a new one ?',
              accept: () => {
                //this.deleteNote(note);
              },
              reject: () => {
                this.showDuplicateReportDialog = false;
                this.showCheckIn = false;
                this.showCheckout = false;
                this.handleTabActive('');
              }
            });
          } else {
            this.showLoader = false;
          }
          this.inspectionUtilsService.latestReportData = resp?.data;
          this.handleCheckInAndCheckOut(type);
        }, error:(error: any) => {
            console.error('Error fetching vehicle latest report data', error);
            this.showLoader = this.noRAClicked = false;
        }
      })
  }

  handleCheckInAndCheckOut(type:string){
    this.noRAClicked = false;
    this.showCheckIn = type == 'IN' ? true : false;
    this.showCheckout = type == 'OUT' ? true : false;
    if(this.showCheckIn){
      this.handleTabActive('checkin');
      this.checkInOutForm.enable();
      this.checkInOutForm.get('mileageOut')?.disable();
      this.checkInOutForm.get('handtrucks')?.disable();
      this.checkInOutForm.get('furniturePads')?.disable();
    }else if(this.showCheckout){
      this.handleTabActive('checkout');
      this.checkInOutForm.enable();
      this.checkInOutForm.get('handtrucksReturned')?.disable();
      this.checkInOutForm.get('furniturePadsReturned')?.disable();
      this.checkInOutForm.get('mileageIn')?.disable();
    }
    if(!this.newRAObjectData.customerPresent){
      this.disableFieldsWhenCustomerNotpresent();
    }
    if(this.raByUnitData.contractSearchResults){
      this.handleContractunitList(type,this.raByUnitData.contractSearchResults,this.newRAObjectData);
    }else if(!this.raByUnitData.contractSearchResults && this.raByUnitData.vehicleValidation){
      this.manualRA.inspectionType = type;
      this.manualRA.vehicleValidation[this.unitNumber] = this.raByUnitData.vehicleValidation;
    }
    this.patchFormData(this.newRAObjectData);
    setTimeout(()=>{
      let myElement = document.getElementById('checkInNCheckOut');
      if (myElement) {
        myElement.scrollIntoView(true);
      }
    },100)
    this.newRAObjectData.inspectionType = type;
  }

  patchFormData(reportData:any){
    if(reportData && !this.inspectionTabs.noRA){
      this.checkInOutForm.patchValue({
        signingCustomer : reportData.signingCustomer,
        emailAddress : reportData.emailAddress,
        fuel : this.handleFuelLevel(reportData.fuel),
        mileageOut : reportData.mileageOut,
        mileageIn: reportData.mileageIn ? reportData.mileageIn : '',
        furniturePads : reportData.furniturePads,
        handtrucks : reportData.handtrucks,
        handtrucksReturned : reportData.handtrucksReturned!=null ? reportData.handtrucksReturned : 0,
        furniturePadsReturned: reportData.furniturePadsReturned!=null ? reportData.furniturePadsReturned : 0
      });
    }else{
      this.checkInOutForm.get('signingCustomer')?.patchValue(reportData.signingCustomer);
      this.checkInOutForm.get('fuel')?.patchValue(this.handleFuelLevel(reportData.fuel));
      if(this.showCheckout){
        this.checkInOutForm.get('handtrucksReturned')?.disable();
        this.checkInOutForm.get('furniturePadsReturned')?.disable();
      }
    }
    if(this.newRAObjectData.isRaManualEntry){
      this.handleNonRAData();
    }
  }

  handleNonRAData(){
    this.checkInOutForm?.reset();
    this.checkInOutForm.get('fuel')?.patchValue('FULL');
    if(!this.noRAClicked){
      this.checkInOutForm.get('signingCustomer')?.patchValue(this.newRAObjectData.signingCustomer);
      this.checkInOutForm.get('emailAddress')?.patchValue(this.newRAObjectData.emailAddress);
    }
    if(!this.newRAObjectData.customerPresent){
      this.checkInOutForm.get('customerNotPresent')?.patchValue(true);
    }
  }

  handleContractunitList(searchType:string, contract:any, newRa:any){
    if(newRa.mileageOut || newRa.mileageIn) return;

    contract.contractUnitList.forEach((list:any)=>{
      if(list.seqNum == 1){
        newRa.unitNumber = list.unitNumber;
        newRa.vehicleInfo[newRa.unitNumber] = this.raByUnitData.vehicleValidation;
        newRa.mileageOut = list.mileageOut;
        newRa.mileageIn = list.mileageIn;
        if (searchType == "IN") {
            newRa.reeferStandbyHours = list.standbyHoursIn;
            newRa.reeferRunningHours = list.reeferHoursIn;
            newRa.fuel = list.fuelLevelOut;
        } else {
            newRa.reeferRunningHours = list.reeferHoursOut;
            newRa.reeferStandbyHours = list.standbyHoursOut;
            newRa.fuel = list.fuelLevelOut;
        }
      }else if(list.seqNum == 2){
        newRa.secondaryUnitNumber = list.unitNumber;
        newRa.vehicleInfo[newRa.secondaryUnitNumber] = this.raByUnitData.secondaryVehicleValidation;
      }
    })
  }


  onClickCustomerNotpresentInChangeRA(){
    if(!this.manualRA.customerPresent){
      this.manualRA = this.commonService.manualRAObject();
      this.newRAObjectData.customerPresent = false;
    }else{
      this.newRAObjectData.customerPresent = true;
    }

  }

  goToHistory() {
    this.router.navigate(['/history', this.unitNumber]);
  }

  findUnit() {
    this.showLoader = true;
    this.handleResetDataWhenUnitSearched();
    this.unitService.getVehicleData(this.unitNumber).subscribe({
      next:(searchResults:any) => {
        this.raByUnitData = searchResults?.data;
        this.showLoader = false;
        this.showCheckIn = this.showCheckout = false;
        if(this.raByUnitData.contractSearchResults){
          this.raNumber =  this.raByUnitData.contractSearchResults.transNumber;
          this.handleValidRAData();
          this.isVehicleTypeElectric = this.raByUnitData.vehicleValidation.electric;
          this.locationDifferent = this.showLocationAlert = (this.commonService.getDeviceLocationId() != this.raByUnitData.contractSearchResults.pickupLocation)
          this.dynamicWarningMessage = `Warning: Current tablet location ${this.commonService.getDeviceLocationId()} does not match the manualRA's expected location of ${this.raByUnitData.contractSearchResults.pickupLocation}`;
        }else if(!this.raByUnitData.contractSearchResults && this.raByUnitData.vehicleValidation){
          this.unitFoundNoRA = true;
          this.isVehicleTypeElectric = this.raByUnitData.vehicleValidation.electric;
          this.newRAObjectData = this.commonService.newRaObject('OUT');
          this.newRAObjectData.unitNumber = this.raByUnitData.vehicleValidation.unitNumber;
        }else if(!this.raByUnitData.vehicleValidation){
          this.unitNotFound = true;
        }
      },error:(error: any) => {
        console.error('Error fetching vehicle data', error);
        this.raByUnitData= {};
        this.unitNotFound = true;
        this.showLoader = false;
      }
    });
  }

  handleValidRAData(){
    let newRa = this.newRAObjectData;
    let contract = this.raByUnitData.contractSearchResults;
    newRa.raNumber = contract.transNumber;
    newRa.transactionId = contract.transactionId;
    newRa.customerName = contract.customerFirstName;
    newRa.signingCustomer = contract.customerFirstName;
    newRa.emailAddress = contract.customerEmail;
    newRa.dateOut = this.raByUnitData.utcDateOut;
    newRa.furniturePads = contract.numFurniturePads;
    newRa.customerNumber = contract.customerNumber;
    newRa.billGroup = contract.billGroup;
    newRa.customerDistrict = contract.customerDistrict;
    newRa.unitNumber = this.raByUnitData.vehicleValidation.unitNumber;
    newRa.handtrucks = 0;

    if(contract.hasHandtruck){
      newRa.handtrucks = contract.miscItemList.find((list:any)=>list.miscCode == "HT")?.quantity;
    }

    if (contract.customerFirstName) {
      newRa.customerName += " " + contract.customerLastName;
      newRa.signingCustomer += " " + contract.customerLastName;
    } else {
      newRa.customerName = contract.customerLastName;
      newRa.signingCustomer = contract.customerLastName;
    }

    if (contract.driverList.length) {
      var driver = contract.driverList[0];
      newRa.signingCustomer = driver.firstName + " " + driver.lastName;
    }
  }

  handleFuelLevel(value:any){
    return this.isVehicleTypeElectric ? this.fuelLevelObj.fuelLevelElectric[value] : this.fuelLevelObj.fuelLevelNonElectric[value];
  }

  handleResetDataWhenUnitSearched(){
    this.resetMarkers();
    this.locationDifferent = this.showLocationAlert = this.noRAClicked =this.unitNotFound = this.unitFoundNoRA = false;
    this.showCheckIn = this.showCheckout = false;
    this.checkInOutForm.reset();
    this.checkInOutForm.enable();
    this.setRAObjects();
    this.handleTabActive('');
    this.newRAObjectData.isRaManualEntry = false;
    this.commonService.isInspectionComplete = false;
    this.commonService.svgList = {};
  }

  onCreateRA(manualRA:any) {
   this.newRAObjectData.emailAddress = manualRA.emailAddress
   this.newRAObjectData.customerPresent = !manualRA.customerPresent
   this.newRAObjectData.raNumber = manualRA.raNumber
   this.newRAObjectData.signingCustomer = manualRA.signingCustomer
   this.newRAObjectData.vehicleValidation = manualRA.vehicleValidation;
   this.newRAObjectData.secondaryUnitNumber = manualRA.secondaryUnitNumber;
   this.newRAObjectData.customerName = null;
   this.newRAObjectData.dateOut = null;
   this.newRAObjectData.isRaManualEntry = true;
    this.onCancelRA();
    this.handleTabActive('');
  }

  onCancelRA() {
    this.unitFoundNoRA = false;
    this.changeRA = false;
  }

  onChangeRA() {
    this.changeRA = true;
    this.unitFoundNoRA = true;
    this.showCheckout = this.showCheckIn = false;
    this.handleTabActive('');
  }

  onClickNonRA(){
    this.noRAClicked = this.showCheckout = true;
    this.showCheckIn = false;
    this.handleNonRAData();
    this.disableFieldsWhenCustomerNotpresent();
    this.handleTabActive('noRA');
    this.checkInOutForm.get('customerNotPresent')?.disable();
    this.newRAObjectData = this.commonService.newRaObject('OUT');
    this.newRAObjectData.unitNumber = this.unitNumber;
    this.newRAObjectData.inspectionType = 'OUT';
    this.newRAObjectData.customerPresent = false;
    this.newRAObjectData.isRaManualEntry = false;
    this.newRAObjectData.vehicleInfo[this.unitNumber] = this.raByUnitData.vehicleValidation;
  }

  onClickCustomerNotpresent(){
    this.newRAObjectData.customerPresent = !!this.checkInOutForm.get('customerNotPresent')?.value;
    if(!this.checkInOutForm.get('customerNotPresent')?.value){
      if(this.showCheckout){
        this.checkInOutForm.get('mileageOut')?.enable();
        this.checkInOutForm.get('mileageIn')?.disable();
      }else if(this.showCheckIn){
        this.checkInOutForm.get('furniturePads')?.disable();
        this.checkInOutForm.get('handtrucks')?.disable();
      }
      this.checkInOutForm.get('signingCustomer')?.disable();
      this.checkInOutForm.get('emailAddress')?.disable();
    }else{
      this.checkInOutForm.enable();
      if(this.showCheckIn){
        this.checkInOutForm.get('mileageOut')?.disable();
        this.checkInOutForm.get('furniturePads')?.disable();
        this.checkInOutForm.get('handtrucks')?.disable();
      }else{
        this.checkInOutForm.get('mileageIn')?.disable();
      }
    }
  }

  disableFieldsWhenCustomerNotpresent(){
    this.checkInOutForm.get('customerNotPresent')?.patchValue(true);
    this.checkInOutForm.disable();
    this.checkInOutForm.get('fuel')?.enable();
    this.checkInOutForm.get('reeferStandbyHrs')?.enable();
    this.checkInOutForm.get('reeferRunningHrs')?.enable();
    if(this.newRAObjectData.customerPresent){
      this.checkInOutForm.get('customerNotPresent')?.enable();
    }
    if(this.showCheckIn){
      this.checkInOutForm.get('mileageIn')?.enable();
    }else{
      this.checkInOutForm.get('mileageOut')?.enable();
    }
  }

  handleAlertClose(flag:boolean){
    this.showLocationAlert = flag;
  }

  handleTabActive(type: string) {
    this.inspectionTabs = new inspectionType();
    if (type) this.inspectionTabs[type as keyof inspectionType] = true;
  }

  beginInspection(){
      if (this.newRAObjectData.secondaryUnitNumber) {
        this.newRAObjectData.secondaryUnitWireframeGroupId = this.newRAObjectData?.vehicleInfo[this.newRAObjectData.secondaryUnitNumber]?.wireframeGroupId;
      } else {
        this.newRAObjectData.secondaryUnitWireframeGroupId = null;
      }

      this.newRAObjectData.inspectionEndTimeInMilliseconds = null;
      this.newRAObjectData.inspectionStartTimeInMilliseconds = Date.now();
      this.newRAObjectData.locationId = this.commonService.getDeviceLocationId();
      this.newRAObjectData.primaryUnitWireframeGroupId = this.newRAObjectData.vehicleInfo[this.newRAObjectData.unitNumber] ? this.newRAObjectData.vehicleInfo[this.newRAObjectData.unitNumber].wireframeGroupId : this.raByUnitData.vehicleValidation?.wireframeGroupId;
      this.newRAObjectData.sendEmail = false;
      this.newRAObjectData.userSso = this.commonService.getSsoId();

      this.newRAObjectData.signingCustomer = this.checkInOutForm.value.signingCustomer || null;
      this.newRAObjectData.emailAddress = this.checkInOutForm.value.emailAddress;
      this.newRAObjectData.fuel = this.isVehicleTypeElectric  ? this.fuelLevelObj.fuelLevelElectric.indexOf(this.checkInOutForm.value.fuel) : this.fuelLevelObj.fuelLevelNonElectric.indexOf(this.checkInOutForm.value.fuel);
      this.newRAObjectData.mileageIn = this.checkInOutForm?.value?.mileageIn ? this.checkInOutForm.value.mileageIn : this.newRAObjectData.mileageIn;
      this.newRAObjectData.mileageOut = this.checkInOutForm.value.mileageOut ? this.checkInOutForm.value.mileageOut : this.newRAObjectData.mileageOut;
      this.newRAObjectData.furniturePadsReturned = this.checkInOutForm.value.furniturePadsReturned !== undefined ? this.checkInOutForm.value.furniturePadsReturned : null;
      this.newRAObjectData.handtrucksReturned = this.checkInOutForm.value.handtrucksReturned !== undefined ? this.checkInOutForm.value.handtrucksReturned : null;
      this.newRAObjectData.furniturePads = this.showCheckIn ? this.newRAObjectData.furniturePadsReturned :this.checkInOutForm.value.furniturePads;
      this.newRAObjectData.handtrucks = this.showCheckIn ? this.newRAObjectData.handtrucksReturned :(this.checkInOutForm.value.handtrucks ? this.checkInOutForm.value.handtrucks : this.newRAObjectData.handtrucks);
      if(this.raByUnitData.vehicleValidation.isReefer){
        this.newRAObjectData.reeferRunningHours = this.checkInOutForm.value.reeferRunningHrs ? this.checkInOutForm.value.reeferRunningHrs : this.newRAObjectData.reeferRunningHours;
        this.newRAObjectData.reeferStandbyHours = this.checkInOutForm.value.reeferStandbyHrs ? this.checkInOutForm.value.reeferStandbyHrs : this.newRAObjectData.reeferStandbyHours;
      }else{
        this.newRAObjectData.reeferRunningHours = null;
        this.newRAObjectData.reeferStandbyHours = null;
      }

      this.commonService.manualRAObjectData = this.manualRA;
      this.commonService.raByUnitData = this.raByUnitData;
      console.log('newRAObjectData : ',this.newRAObjectData);
      this.commonService.setFinalReportData(this.newRAObjectData)
      this.inspectionUtilsService.vehicleData = this.raByUnitData.vehicleValidation;
      this.router.navigateByUrl('/inspection-details-new',  { state: { vehicleData: this.raByUnitData.vehicleValidation } });
    }

    handleInspectionComplete(){
      this.raByUnitData = {};
      this.showCheckIn = this.showCheckout = this.locationDifferent = this.showLocationAlert = false;
      this.unitNumber = '';
      this.handleTabActive('');
      this.setRAObjects();
      this.resetInspectionReportDetails();
      this.commonService.raByUnitData = {};
      this.commonService.manualRAObjectData = {};
    }

    resetInspectionReportDetails(){
      this.commonService.setFinalReportData(null);
      this.resetMarkers();
    }

    resetMarkers(){
      this.inspectionUtilsService.standardMarkers = {};
      this.inspectionUtilsService.damageMarkers = {};
      this.inspectionUtilsService.vehicleWireframeData = [];
    }

    resetHomeWhenPenskeLogoClicked(){
      this.commonService.resetData$.subscribe(res=>{
        if(res==true){
          this.handleInspectionComplete();
        }
      })
    }

    onClickNumAndLetter() {
      // Toggle the isNumAndLetter value on click
      this.isNumAndLetter = !this.isNumAndLetter;
    }
}
