export const environment = {
    //@ts-ignore
    backend_url: window["env"]["backend_url"] || 'default',
    //@ts-ignore
    auth_server_base_url: window["env"]["auth_server_base_url"],
    version: '1.0.0',
    
    //@ts-ignore
    ssologout_url: window["env"]["ssologout_url"]
}
