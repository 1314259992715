import { Component, Input, Output, EventEmitter, OnInit, HostListener, ChangeDetectorRef, OnChanges, SimpleChanges, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { wireframeData } from '../../interfaces/image.model';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SafeHtml } from '@angular/platform-browser';
import { damageData, saveReport } from '../../interfaces/savereport.model';
import { CommonService } from '../../services/common/common.service';
import { Observable } from 'rxjs';
import { InspectionUtilsService } from '../../services/inspection-utils/inspection-utils.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SvgComponent } from '../svg/svg.component';
import { ButtonModule } from 'primeng/button';
import { BadgeModule } from 'primeng/badge';
import { AlertComponent } from '../alert/alert.component';
import { ImageModule } from 'primeng/image';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-inspection-details-mobile',
  standalone: true,
  imports:[CommonModule, FormsModule, SvgComponent, ButtonModule, BadgeModule, AlertComponent, ImageModule, CheckboxModule],
  templateUrl: './inspection-details-mobile.component.html',
  styleUrls: ['./inspection-details-mobile.component.scss']
})
export class InspectionDetailsMobileComponent implements AfterViewInit{
  @Output() cardClick = new EventEmitter<any>();
  @Output() onWireFrameLoadMobile = new EventEmitter<any>();
  @Output() damageChange = new EventEmitter<string>();

  @Input() removeMarkers!: (all?: boolean) => void;
  @Input() wireFrameClick!: (event: MouseEvent) => void;
  @Input() updateDamageType!: (type: any) => void;
  @Input() onDeleteInspImg!: (image: any) => void;
  @Input() onEditInspImg!: () => void;
  @Input() toggleComment!: () => void;
  @Input() previousDamage!: () => void;
  @Input() nextDamage!: () => void;
  @Input() removeDamage!: () => void;
  @Input() onWalkAroundDone!: () => void;
  @Input() getCurrStandardMarkers!:() => any[];
  @Input() getCurrDamageMarkers!:() => any[];
  @Input() reportTypes!: (selectedDamage:string) => void;
  @Input() handleInspectionData!:()=>void;
  @Input() handleMarkers!:()=>void;
  @Input() loadSelectedWireFrame!:(wireframeId:number)=>void;
  @Input() updateToSelectedMarker!:(marker: any, isNewStandardMarker?: boolean)=>void;
  @Input() updateMarkerColor!:()=>void;
  @Input() createNewMarker!:()=>void;
  @Input() checkForNoDamageType!:()=>void;
  @Input() checkForStandardImages!:()=>void;
  @Input() checkForStandardCheckinImages!:()=>void;
  @Input() resetActiveCard!:()=>void;
  @Input() checkPhotoNDamageStatus!:()=>void;
  @Input() closeAlert!:(visable:boolean)=>void;

  @Input() vehicleWireframeData: any = [];
  @Input() damageList:any = [];
  @Input() showLoader:boolean=false;
  @Input() markerCounter:number=0;
  @Input() noStandardImage:boolean = false;
  @Input() selectedDamage:string='';
  @Input() damageNoType:boolean=false;
  @Input() disableWalkAroundBtn:boolean=false;
  @Input() damageType:string='';
  @Input() majorDamage: any = '';
  @Input() showComment: boolean = false;
  @Input() disableStandardPhoto:boolean=false;
  @ViewChild('detailsPanel') detailsPanel!: ElementRef;
  @ViewChild('wireframeSvg') vehicleImage!: ElementRef<HTMLImageElement>;
  @Input() nextArrowvisible: boolean = false;
  @Input() backArrowVisible: boolean = true;
  constructor(
      private router: Router,
      public commonService: CommonService,
      private cdr: ChangeDetectorRef,
      public inspectionUtilsService: InspectionUtilsService
  ) {}

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    console.log('host listnet clicked!')
    if(this.inspectionUtilsService.selectedMarker.id && !this.inspectionUtilsService.selectedMarker.damageType && this.selectedDamage=='damagePhoto'){
      this.damageNoType = true;
    }else{
      this.damageNoType = false;
    }
    if(this.inspectionUtilsService.selectedMarker.id && this.inspectionUtilsService.selectedMarker.damageType && !this.inspectionUtilsService.selectedMarker.imageSrc && this.selectedDamage=='damagePhoto'){
      this.disableWalkAroundBtn = true;
    }else{
      this.disableWalkAroundBtn = false;
    }
  }

  ngAfterViewInit(): void {
    if(this.vehicleImage){
      this.inspectionUtilsService.vehicleImageEl = this.vehicleImage;
    }
  }

  onCardClick(wireframeData: any) {
    this.cardClick.emit(wireframeData);
    let  id= "mobileInspectionDiv"+(this.inspectionUtilsService.selectedInspectionCardDetails.number).toString();
   let myElement = document.getElementById(id);
      if (myElement) {
        myElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
     }


  }

  onWireFrameLoad(event: any) {
    this.onWireFrameLoadMobile.emit(event);
  }

  onDetailsNextCard() {
  let vehicleWireframeDataIndex = this.vehicleWireframeData.findIndex((data:any)=>data.number == this.inspectionUtilsService.selectedInspectionCardDetails.number);
    this.inspectionUtilsService.selectedInspectionCardDetails = this.inspectionUtilsService.newWireframeObj();
   if(vehicleWireframeDataIndex < this.vehicleWireframeData.length-1){

    vehicleWireframeDataIndex = vehicleWireframeDataIndex +2;

    let  id= "mobileInspectionDiv"+vehicleWireframeDataIndex.toString();
    let myElement = document.getElementById(id);
    if (myElement) {
      myElement.scrollIntoView({ behavior: 'smooth', block: 'end' });

    }
  }else{
      vehicleWireframeDataIndex = 1;
      let  id= "mobileInspectionDiv"+vehicleWireframeDataIndex.toString();
      let myElement = document.getElementById(id) ;
      if (myElement) {
        myElement.scrollIntoView(true);
      }


   }
   this.inspectionUtilsService.selectedInspectionCardDetails = this.inspectionUtilsService.newWireframeObj();
    }
  onDetailsBeforeCard(){
    let vehicleWireframeDataIndex = this.vehicleWireframeData.findIndex((data:any)=>data.number == this.inspectionUtilsService.selectedInspectionCardDetails.number);
  if(vehicleWireframeDataIndex == 0){
  let  id= "mobileInspectionDiv"+this.vehicleWireframeData[this.vehicleWireframeData.length-1].number.toString();
  let myElement = document.getElementById(id);
  if (myElement) {
    myElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
 }else{
     let  id= "mobileInspectionDiv"+vehicleWireframeDataIndex.toString();
     let myElement = document.getElementById(id) ;
     if (myElement) {
      myElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  this.inspectionUtilsService.selectedInspectionCardDetails = this.inspectionUtilsService.newWireframeObj();
  }

  notifyParent() {
    this.damageChange.emit(this.selectedDamage);
  }

}
