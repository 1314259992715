  <div class="content">
    <div *ngIf="isTabletOrBigger$">
      <div class="textAlignCenter mb-4" id="transactionDetails">
        <h4 class="blue-color"><b>Inspection Details</b></h4>
        <span class="d-none d-md-block">
          <h5 class="mt-3">Click each card below to attach images, describe damage via notes or mark item not applicable
            (N/A).</h5>
        </span>
      </div>

      <div class="d-none d-md-block">
        <p-carousel [value]="vehicleWireframeData" [numVisible]="3" [numScroll]="3" [circular]="true" [page]="currentPage">
          <ng-template pTemplate="item" let-wireframeCard>
            <div class="vehicleCard shadow p-3 mb-5" [ngClass]="[wireframeCard.active ? 'activeCard':'', inspectionUtilsService.checkWireframeCardStatus(wireframeCard,commonService.getFinalReportData().inspectionType) ? 'shadeColor':' bg-white']" (click)="onCardClick(wireframeCard)">
              <h6><p-badge class="wireFrame-card-badge" [ngClass]="{'active': inspectionUtilsService.selectedInspectionCardDetails?.wireframeId ? wireframeCard?.wireframeId === inspectionUtilsService.selectedInspectionCardDetails.wireframeId : false, 'edited':inspectionUtilsService.checkWireframeCardStatus(wireframeCard,commonService.getFinalReportData().inspectionType)}"
                  [value]="wireframeCard.number" severity="info" /> {{ wireframeCard.title }}
                  <button pButton pRipple type="button" class="top-right-button float-right pi"
                  [ngClass]="inspectionUtilsService.checkWireframeCardStatus(wireframeCard,commonService.getFinalReportData().inspectionType) ? 'pi-pencil':'pi-camera'" pTooltip="Add"
                  [disabled]="disableStandardPhoto || disableWalkAroundBtn || damageNoType" data-bs-toggle="modal" data-bs-target="#cameraModal"></button>
                </h6>
              <div class="card-container">
                <div class="margin-right-5P">
                  <p class="card-status">Damage: {{ commonService.getFinalReportData().inspectionType=='OUT' ? wireframeCard.damage.OUT : wireframeCard.damage.IN }}</p>
                  <p class="card-status">Comments: {{ commonService.getFinalReportData().inspectionType=='OUT' ? wireframeCard.comments.OUT : wireframeCard.comments.IN }}</p>
                  <p class="card-status">STD Photo: {{ commonService.getFinalReportData().inspectionType=='OUT' ? wireframeCard.stdPhoto.OUT : wireframeCard.stdPhoto.IN }}</p>
                </div>
                <div class="image-container">
                  <!-- <img id="list" src="{{ wireframeCard.svgCode }}" alt="{{ wireframeCard.title }}" class="img-fluid"> -->
                  <app-svg [wireframeId]="wireframeCard.wireframeId" (onWireFrameLoad)="onWireFrameLoad($event)"></app-svg>
                </div>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>

      <div class="d-none d-md-block">
        <div class="col-12" id="reportPane">
          <div class="shadow-lg p-3 mb-5 bg-white rounded col-12 margin-right-1P paddingBottom5P">
            <div *ngIf="!inspectionUtilsService.selectedInspectionCardDetails?.number" class="textAlignCenter" id="unitSearchText">
              <h4 class="greyColor"><b>WELCOME TO INSPECTION REPORT</b></h4>
              <span class="d-none d-md-block blue-color">
                <h5>Use the wireframes to capture photos and notes</h5>
              </span>
              <span class="greyColor d-none d-md-block">The wireframes can be revisited anytime</span><br>
            </div>
            <div *ngIf="inspectionUtilsService.selectedInspectionCardDetails && inspectionUtilsService.selectedInspectionCardDetails.number" class="parent-container padding2P"
              id="detailsPanel" #detailsPanel>
              <p-button *ngIf="nextArrowvisible == false" class="float-right nextArrow" icon="pi pi-arrow-right" [label]=" 'Next' " [iconPos]=" 'right' "  [text]="true"
              [disabled]="disableStandardPhoto || disableWalkAroundBtn || damageNoType" (click)="onDetailsNextCard()" />
              <p-button  *ngIf="backArrowVisible" class="float-left nextArrow" icon="pi pi-arrow-left" [label]=" 'Back' " [rounded]="true" [text]="true"
              [disabled]="disableStandardPhoto || disableWalkAroundBtn || damageNoType" (click)="onDetailsBeforeCard()" />
              <button *ngIf="nextArrowvisible" type="submit" class="float-right btn btn-yellow btn-xs btn-sm btn-md btn-width p-1 border-0 walkpadding"
                (click)="onWalkAroundDone()">Walk-Around Done</button>
              <app-alert [type]="'danger'" *ngIf="nextArrowvisible"
                [message]="inspectionUtilsService.getWalkAroundDoneErrorMessgae()"
                [duration]="2000" [icon]="'#exclamation-triangle-fill'" [visible]="noStandardImage">
             </app-alert>
              <div>
                <div class="col-12 content-container">
                  <div class="insp-details-container">
                    <h6>
                      <p-badge class="wireFrame-card-badge active" [value]="(inspectionUtilsService.selectedInspectionCardDetails.number).toString()" severity="info" /> {{
                      inspectionUtilsService.selectedInspectionCardDetails.title }}
                    </h6>
                    <div class="flex flex-wrap gap-3">
                      <div class="blue-color mb-4"><b>Report Types</b></div>
                      <span class="flex align-items-center  margin-right-1P">
                        <p-radioButton name="standardPhoto" value="standardPhoto" [(ngModel)]="selectedDamage"
                          inputId="standardPhoto" (onClick)="reportTypes('standardPhoto')"
                          [disabled]="disableStandardPhoto || disableWalkAroundBtn" />
                        <label for="standardPhoto" class="ms-2 radio-buttons">Standard Photos</label>
                      </span>
                      <span class="flex align-items-center">
                        <p-radioButton name="damagePhoto" value="damagePhoto" [(ngModel)]="selectedDamage"
                          inputId="damagePhoto" (onClick)="reportTypes('damagePhoto')" />
                        <label for="damagePhoto" class="ms-2 radio-buttons">Damage Photos</label>
                      </span>
                    </div>
                    <div>
                      <app-alert [type]="'danger'"
                        [message]="'Please select a damage type!!'"
                        [duration]="-1" [icon]="'#exclamation-triangle-fill'" [visible]="damageNoType">
                      </app-alert>
                      <app-alert [type]="'danger'"
                                [message]="'Photo is required to the Damage '+inspectionUtilsService.selectedMarker?.number"
                                [duration]="-1" [icon]="'#exclamation-triangle-fill'" [visible]="disableWalkAroundBtn">
                      </app-alert>
                    </div>
                    <div class="d-flex align-items-center justify-content-center">
                      <div *ngIf="isTabletOrBigger$" id="wireframeId{{inspectionUtilsService.selectedInspectionCardDetails.wireframeId}}" class="wireframeContainer ng-scope penskeHiglightSection"
                          (click)="wireFrameClick($event)">
                          <div #wireframeSvg [innerHTML]="inspectionUtilsService.wireFrameImg"></div>
                          <div class="standard-marker-container" *ngIf="selectedDamage !== 'damagePhoto'" >
                            <i class="pi pi-map-marker" [id]="marker.id" *ngFor="let marker of inspectionUtilsService.getStandardMarkers()[inspectionUtilsService.selectedInspectionCardDetails.wireframeId];index as i" [ngStyle]="{'color': marker.color, 'top': marker.positionY+'%', 'left': marker.positionX+'%'}" style="position: absolute;width: 10px;height: 10px;">{{marker.number}}</i>
                          </div>
                          <div class="marker-container" *ngIf="selectedDamage == 'damagePhoto'" >
                            <i class="pi pi-map-marker" [id]="marker.id" *ngFor="let marker of inspectionUtilsService.getDamageMarkers()[inspectionUtilsService.selectedInspectionCardDetails.wireframeId];index as i" [ngStyle]="{'color': marker.color, 'top': marker.positionY+'%', 'left': marker.positionX+'%','background-color':marker?.showNewDamageField?'red':'none', 'border-top-left-radius':marker?.showNewDamageField?'10px':'none'}" style="position: absolute;width: 10px;height: 10px;">{{marker.number}}</i>
                          </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
              <!-- Standard photo section starts -->
              <div *ngIf="selectedDamage=='standardPhoto'" class="col-12 content-container">
                <span class="fw-bold">Photos</span>
                <div class="card mb-2">
                  <div class="card-body">
                    <i class="pi pi-camera blue-color fw-bold"></i>
                    <button type="button" class="btn whiteButton margin-top-2MP" data-bs-toggle="modal" data-bs-target="#cameraModal">
                      Take Photo
                    </button>
                    <!-- <span *ngFor="let image of inspectionUtilsService.selectedInspectionCardDetails?.images"> -->
                    <span *ngIf="inspectionUtilsService.selectedMarker.imageSrc">
                      <p-image src="{{inspectionUtilsService.selectedMarker.imageSrc}}" alt="Image" width="76" [preview]="true"></p-image>
                      <i class="pi pi-pencil ms-1" data-bs-toggle="modal" data-bs-target="#cameraModal" (click)="onEditInspImg()"></i>
                    </span>
                    <button type="button" class="btn whiteButton float-right noPadding" (click)="toggleComment()">
                      <i class="pi pi-comment"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="showComment">
                  <span class="fw-bold">Comments</span>
                  <textarea class="mb-2" id="comments" name="comments" rows="4" cols="50" [(ngModel)]="inspectionUtilsService.selectedMarker.comments"></textarea>
                </div>
                <div class="d-flex align-items-center" *ngIf="getCurrStandardMarkers()?.length">
                    <button class="btn btn-primary" (click)="previousDamage()" [disabled]="inspectionUtilsService.currentFrameMarkerCounter === 0 || disableWalkAroundBtn">
                      <i class="pi pi-step-backward"></i>
                    </button>
                      <span class="mx-3">Standard photos ({{ inspectionUtilsService.selectedMarker.number}})</span>
                    <button class="btn btn-primary" (click)="nextDamage()" [disabled]="disableWalkAroundBtn">
                      <i class="pi pi-step-forward"></i>
                    </button>
                    <button class="btn btn-danger ms-4" data-bs-toggle="modal" data-bs-target="#deleteMarkerModal"
                      [disabled]="disableWalkAroundBtn || damageNoType">
                      <i class="bi bi-trash"></i> Delete
                    </button>
                </div>
                <!-- <button class="float-right btn btn-sm btn-primary btn-md fw-bold d-flex align-items-center" label="Save" >save</button>
                <button class="margin-right-1P float-right btn btn-secondary btn btn-sm btn-md fw-bold d-flex align-items-center " label="Cancel" severity="secondary" > Cancel</button> -->
              </div>
              <!-- Standard photo section ends -->

              <!-- Damage photo section starts-->
              <div class="flex-container">
                <div *ngIf="selectedDamage === 'damagePhoto'" class="col-6 content-container mx-auto width-40">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="fw-bold blue-color width-50">Damage Types</span>
                    <div class="d-flex align-items-center blue-color cursor-pointer width-50" (click)="inspectionUtilsService.showDefinitions()"><span class="fw-medium mx-1">Definitions </span><i class="pi pi-external-link"></i></div>
                  </div>
                  <table class="table table-borderless" id="damageTypeSection">
                    <tbody>
                      <tr>
                        <td class="p-1">
                          <span class="d-flex align-items-center">
                            <p-radioButton name="dent" value="dent" [(ngModel)]="damageType"
                              (click)="updateDamageType(damageType)" inputId="dent" />
                            <label for="dent" class="ms-2">Dent</label>
                          </span>
                        </td>
                        <td class="p-1">
                          <span class="d-flex align-items-center">
                            <p-radioButton name="bend" value="bend" [(ngModel)]="damageType"
                              (click)="updateDamageType(damageType)" inputId="bend" />
                            <label for="bend" class="ms-2">Bend</label>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="p-1">
                          <span class="d-flex align-items-center">
                            <p-radioButton name="chip" value="chip" [(ngModel)]="damageType"
                              (click)="updateDamageType(damageType)" inputId="chip" />
                            <label for="chip" class="ms-2">Chip</label>
                          </span>
                        </td>
                        <td class="p-1">
                          <span class="d-flex align-items-center">
                            <p-radioButton name="hole" value="hole" [(ngModel)]="damageType"
                              (click)="updateDamageType(damageType)" inputId="hole" />
                            <label for="hole" class="ms-2">Hole</label>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="p-1">
                          <span class="d-flex align-items-center">
                            <p-radioButton name="missing" value="missing" [(ngModel)]="damageType"
                              (click)="updateDamageType(damageType)" inputId="missing" />
                            <label for="missing" class="ms-2">Missing</label>
                          </span>
                        </td>
                        <td class="p-1">
                          <span class="d-flex align-items-center">
                            <p-radioButton name="scratch" value="scratch" [(ngModel)]="damageType"
                              (click)="updateDamageType(damageType)" inputId="scratch" />
                            <label for="scratch" class="ms-2">Scratch</label>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="p-1">
                          <span class="d-flex align-items-center">
                            <p-radioButton name="crack" value="crack" [(ngModel)]="damageType"
                              (click)="updateDamageType(damageType)" inputId="crack" />
                            <label for="crack" class="ms-2">Crack</label>
                          </span>
                        </td>
                        <td colspan="2" class="p-1">
                          <span class="d-flex align-items-center">
                            <p-radioButton name="stained" value="stained" [(ngModel)]="damageType" (click)="updateDamageType(damageType)" inputId="stained" />
                            <label for="stained" class="ms-2">Stains</label>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="p-1">
                          <span class="d-flex align-items-center">
                            <p-checkbox [(ngModel)]="majorDamage" [binary]="true" inputId="majorDamage" (click)="handleMajorDamage()"/>
                            <label for="majorDamage" class="ms-2">Major</label>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex align-items-center"
                    *ngIf="getCurrDamageMarkers()?.length">
                    <button class="btn btn-primary" (click)="previousDamage()" [disabled]="inspectionUtilsService.currentFrameMarkerCounter === 0 || disableWalkAroundBtn || damageNoType">
                      <i class="pi pi-step-backward"></i>
                    </button>
                      <span class="mx-3">Damage ({{ inspectionUtilsService.selectedMarker.number}})</span>
                    <button class="btn btn-primary" (click)="nextDamage()" [disabled]="disableWalkAroundBtn || damageNoType">
                      <i class="pi pi-step-forward"></i>
                    </button>
                    <button class="btn btn-danger ms-4" data-bs-toggle="modal" data-bs-target="#deleteMarkerModal">
                      <i class="bi bi-trash"></i> Delete
                    </button>
                  </div>

                </div>
                <div *ngIf="selectedDamage=='damagePhoto'" class="col-6 content-container">
                  <span class="fw-bold blue-color">Photos</span>
                  <div class="card mb-2" id="takePhotoSection">
                    <div class="card-body">
                      <i class="pi pi-camera blue-color fw-bold"></i>
                      <button type="button" class="btn whiteButton margin-top-2MP" [disabled]="damageNoType" data-bs-toggle="modal" data-bs-target="#cameraModal">
                        Take Photo
                      </button>
                      <!-- <span *ngFor="let image of inspectionUtilsService.selectedMarker?.images"> -->
                        <span *ngIf="inspectionUtilsService.selectedMarker.imageSrc">
                          <p-image src="{{inspectionUtilsService.selectedMarker.imageSrc}}" alt="Image" width="76" [preview]="true"></p-image>
                          <i class="pi pi-pencil ms-1" data-bs-toggle="modal" data-bs-target="#cameraModal" (click)="onEditInspImg()"></i>
                        </span>
                      <!-- <p-image src="https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg" alt="Image"
                        [preview]="true" width="50" /><i class="pi pi-trash margin-right-1P"></i>
                      <p-image src="https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg" alt="Image"
                        [preview]="true" width="50" /><i class="pi pi-trash margin-right-1P"></i>
                      <p-image src="https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg" alt="Image"
                        [preview]="true" width="50" /><i class="pi pi-trash margin-right-1P"></i> -->
                    </div>
                  </div>
                  <div class="fw-bold blue-color">Comments</div>
                  <textarea class="mb-2" id="comments" name="comments" rows="4" cols="50" [(ngModel)]="inspectionUtilsService.selectedMarker.comments" (blur)="inspectionUtilsService?.reValidateCardDetails(commonService.getFinalReportData().inspectionType)"></textarea>
                  <!-- <p-button class="float-right" label="Save" />
                  <p-button class="margin-right-1P float-right cancelBtn" label="Cancel" severity="secondary" /> -->
                </div>
              </div>
              <!-- Damage Photo sections ends -->
            </div>

          </div>

          <div class="shadow-lg p-3 mb-5 bg-white rounded col-12 margin-right-5P">
            <p>Once all the cards above are marked complete the Walk-Around button will enable.</p>
            <div class="form-group">
              <app-alert [type]="'danger'" *ngIf="noStandardImage"
              [message]="inspectionUtilsService.getWalkAroundDoneErrorMessgae()"
                        [duration]="2000" [icon]="'#exclamation-triangle-fill'" (closeAlertEvent) ="closeAlert($event)" [visible]="noStandardImage">
              </app-alert>
              <button type="submit" class="btn btn-yellow btn-xs btn-sm btn-md btn-width p-1 border-0 walkpadding"
                (click)="onWalkAroundDone()">Walk-Around Done</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FOR MOBILE -->
    <div class="d-block d-md-none" id="mobileViewDiv" *ngIf="!isTabletOrBigger$">
      <app-inspection-details-mobile
        [vehicleWireframeData]="vehicleWireframeData"
        [damageList]="damageList"
        [showLoader]="showLoader"
        [markerCounter]="markerCounter"
        [noStandardImage]="noStandardImage"
        [nextArrowvisible] = "nextArrowvisible"
        [backArrowVisible] = "backArrowVisible"
        [selectedDamage]="selectedDamage"
        [damageNoType]="damageNoType"
        [disableWalkAroundBtn] = "disableWalkAroundBtn"
        [damageType]="damageType"
        [disableStandardPhoto]="disableStandardPhoto"
        [majorDamage]="majorDamage"
        [majorDamage]="showComment"

        [handleInspectionData]="handleInspectionData"
        [handleMarkers]="handleMarkers"
        [loadSelectedWireFrame]="loadSelectedWireFrame"
        [updateToSelectedMarker]="updateToSelectedMarker"
        [updateMarkerColor]="updateMarkerColor"
        [createNewMarker]="createNewMarker"
        [checkForNoDamageType]="checkForNoDamageType"

        [onDeleteInspImg]="onDeleteInspImg"
        [previousDamage]="previousDamage"
        [nextDamage]="nextDamage"
        [getCurrDamageMarkers]="getCurrDamageMarkers"
        [getCurrStandardMarkers]="getCurrStandardMarkers"
        [updateDamageType]="updateDamageType"
        [reportTypes]="reportTypes"
        [closeAlert]="closeAlert"
        [wireFrameClick]="wireFrameClick"
        [resetActiveCard]="resetActiveCard"
        [onWalkAroundDone]="onWalkAroundDone"
        [checkForStandardImages]="checkForStandardImages"
        [checkForStandardCheckinImages]="checkForStandardCheckinImages"
        [removeDamage]="removeDamage"
        [onEditInspImg]="onEditInspImg"
        [checkPhotoNDamageStatus]="checkPhotoNDamageStatus"
        [toggleComment]="toggleComment"

        (onWireFrameLoadMobile)="onWireFrameLoad($event)"
        (cardClick)="onCardClick($event)"
        (damageChange)="reportTypes($event)"
      ></app-inspection-details-mobile>
    </div>
    <app-camera-overlay (afterPhotoCaptured)="capturedPhoto($event)" [inspectionCardDtls]="inspectionUtilsService.selectedInspectionCardDetails" [selectedDamage]="selectedDamage"></app-camera-overlay>

  </div>

  <app-loader *ngIf="showLoader"></app-loader>

  <!-- Delete Marker Modal -->
  <div class="modal fade" id="deleteMarkerModal" tabindex="-1" aria-labelledby="deleteMarkerModalLabel"
    aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteMarkerModalLabel">Confirm Delete</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Are you sure you want to delete Marker?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary cancelBtn" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-danger" id="confirmLogout" (click)="removeDamage()"
            data-bs-dismiss="modal">Delete</button>
        </div>
      </div>
    </div>
  </div>


<!-- Definitions dialog starts here -->
<p-dialog class="definitions-dialog" header="Type Of Damages" [(visible)]="inspectionUtilsService.showDefinitionsDialog" [draggable]="false" [modal]="true">
  <div class="panel-body panel-body-damage-legend">
		<div class="row">
			<div class="col-sm-3 bold fw-bold">Damage Type</div>
			<div class="col-sm-9 bold fw-bold">Details</div>
		</div>
    <div class="row" *ngFor="let damageTypes of inspectionUtilsService.definitionsData">
			<div class="col-sm-3 bold fw-medium">{{damageTypes.type}}</div>
			<div class="col-sm-9 bold">{{damageTypes.details}}</div>
		</div>
  </div>
</p-dialog>
<!-- Definitions dialog ends here -->
