<div class="overlay">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="loader7">
                    <span class="loader-inner-1"></span>
                    <span class="loader-inner-2"></span>
                    <span class="loader-inner-3"></span>
                    <span class="loader-inner-4"></span>
                </div>
            </div>
        </div>
        <br/><br/>
    </div>
</div>
