<div class="summary w-md-45">
    <div class="summary_container px-md-4">
        <div class="summary_header p-4 text-left">
            <div class="header_r1 mb-1 blue-color">
                <span class="">{{commonService.getFinalReportData().inspectionType=='OUT' ? 'Check-Out' : 'Check-In'}} </span>
                <span>Inspection Summary</span>
            </div>
            <div class="header_r2">Complete email Section and finalize the report.</div>
        </div>
        <div class="summary_contents p-4  shadow">
            <div class="fw-bold text-left mb-3">Report Header</div>
            <div class="d-flex lh-lg">
                <div class="row mb-1">
                    <div><span class="fw-medium text-sm-nowrap fs-14px">Report Date: </span><span class="fs-12px">{{getCurrentData() | date: 'MM/dd/YYYY'}}</span></div>
                    <div><span class="fw-medium fs-14px">Customer: </span><span class="fs-12px">{{commonService.getFinalReportData()?.customerName}}</span></div>
                </div>
                <div class="row mb-1">   
                    <div><span class="fw-medium fs-14px">Signing Driver: </span><span class="fs-12px">{{commonService.getFinalReportData()?.signingCustomer}}</span></div>
                    <div><span class="fw-medium fs-14px">RA Number: </span><span class="fs-12px">{{commonService.getFinalReportData()?.raNumber}}</span></div>
                </div>
            </div>
        </div>
        <div class="summary_inputs p-4 shadow" *ngIf="commonService.getFinalReportData()?.customerPresent">
            <div class="fw-bold text-left mb-3">Email Confirmations</div>
            <form id="checkInOutForm" [formGroup]="summaryForm">
                <div class="summary_input_boxes text-center">
                    <div class="form-group p-float-label mt-2   marginBottom12P">
                        <input class="form-control" [readonly]="summaryForm.get('emailNotRequired')?.value" pInputText type="email" formControlName="to" id="to">
                        <label class="fw-medium fs-14px text-nowrap" [ngClass]="{'required-input':!summaryForm.get('emailNotRequired')?.value}" for="to">Email a copy to:</label>
                    </div>
                    <div class="form-group p-float-label  marginBottom10P">
                        <input class="form-control" [readonly]="summaryForm.get('emailNotRequired')?.value" pInputText type="email" formControlName="cc" id="cc">
                        <label class="fw-medium fs-14px text-nowrap" for="cc">Carbon Copy (CC):</label>                        
                    </div>
                </div>
                <div class="form-group form-check marginBottom10P">
                    <input type="checkbox" class="form-check-input" formControlName="emailNotRequired" id="emailNotRequired">
                    <label class="form-check-label fs-14px text-nowrap" for="emailNotRequired">Not Required</label>
                  </div>
            </form>
        </div>
        <div class="summary_buttons p-4 shadow">
            <div class="fw-medium text-left mb-4 blue-color">Edit or Finalize Report</div>
            <div class=" d-flex justify-content-around ">
                <button class="btn btn-edit fw-medium text-nowrap" (click)="onEdit()">Edit Report</button>
                <button class="btn btn-yellow fw-medium border-0 text-nowrap" [disabled]="summaryForm.get('to')?.invalid && !summaryForm.get('emailNotRequired')?.value && commonService.getFinalReportData()?.customerPresent" (click)="onFinalizeReport()">Finalize Report</button>
            </div>
        </div>
    </div>
</div>
<app-loader *ngIf="showLoader"></app-loader>