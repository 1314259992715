<div *ngIf="!inspectionUtilsService.selectedInspectionCardDetails?.number" class="textAlignCenter" id="unitSearchText">
  <h4 class="greyColor"><b>WELCOME TO INSPECTION REPORT</b></h4>
  <span class="d-none d-md-block blue-color">
          <h5>Use the wireframes to capture photos and notes</h5>
        </span>
  <span class="greyColor d-none d-md-block">The wireframes can be revisited anytime</span><br>
</div>
<div class="shadow p-3 mb-3 bg-white image-item rounded-3" *ngFor="let wireframeCard of vehicleWireframeData"
id="mobileInspectionDiv{{wireframeCard.number}}" [ngClass]="[inspectionUtilsService.checkWireframeCardStatus(wireframeCard,commonService.getFinalReportData().inspectionType) ? 'shadeColor':' bg-white']" >
  <div *ngIf="inspectionUtilsService.selectedInspectionCardDetails.number !== wireframeCard.number" (click)="onCardClick(wireframeCard)">
    <h6><p-badge class="wireFrame-card-badge" [ngClass]="{'active': inspectionUtilsService.selectedInspectionCardDetails?.wireframeId ? wireframeCard?.wireframeId === inspectionUtilsService.selectedInspectionCardDetails.wireframeId : false, 'edited':inspectionUtilsService.checkWireframeCardStatus(wireframeCard,commonService.getFinalReportData().inspectionType)}"
       [value]="wireframeCard.number" severity="info" /> {{ wireframeCard.title }}
      <button pButton pRipple type="button" class="top-right-button float-right pi" pTooltip="Add"
      [ngClass]="inspectionUtilsService.checkWireframeCardStatus(wireframeCard,commonService.getFinalReportData().inspectionType) ? 'pi-pencil':'pi-camera'"
       [disabled]="disableStandardPhoto || disableWalkAroundBtn  || damageNoType" (click)="onCardClick(wireframeCard)"  data-bs-toggle="modal" data-bs-target="#cameraModal"></button>
    </h6>
    <div class="image-details">
      <p class="card-status">Damage: {{ commonService.getFinalReportData().inspectionType=='OUT' ? wireframeCard.damage.OUT : wireframeCard.damage.IN }}</p>
      <p class="card-status">Comments: {{ commonService.getFinalReportData().inspectionType=='OUT' ? wireframeCard.comments.OUT : wireframeCard.comments.IN }}</p>
      <p class="card-status">STD Photo: {{ commonService.getFinalReportData().inspectionType=='OUT' ? wireframeCard.stdPhoto.OUT : wireframeCard.stdPhoto.IN }}</p>
    </div>
    <div class="image-container">
      <!--<img src="{{ wireframeCard.url }}" alt="{{ wireframeCard.title }}">-->
      <!--<app-svg [src]="src" [wireframeId]="wireframeId" (onWireFrameLoad)="onWireFrameLoad($event)" (onWireFrameClick)="wireFrameClick($event)"></app-svg>-->
        <app-svg [wireframeId]="wireframeCard.wireframeId" [isMobile]="true" (onWireFrameLoad)="onWireFrameLoad($event)"></app-svg>
    </div>
  </div>


  <div class="col-12 flex-container" id="reportPaneMobile{{wireframeCard.number}}"
  *ngIf="inspectionUtilsService.selectedInspectionCardDetails.number == wireframeCard.number">
    <div class="shadow-lg p-3 mb-5 bg-white rounded col-12 margin-right-1P paddingBottom20P">
      <div *ngIf="inspectionUtilsService.selectedInspectionCardDetails && inspectionUtilsService.selectedInspectionCardDetails.number" class="parent-container" id="detailsPane">
        <div class="content-container">
          <div class="insp-details-container">
            <h6 class="text-center">
              <p-badge class="wireFrame-card-badge active" [value]="(inspectionUtilsService.selectedInspectionCardDetails.number).toString()" severity="info" /> {{ inspectionUtilsService.selectedInspectionCardDetails.title }}
            </h6>
            <div class="d-flex align-items-center" [ngClass]="backArrowVisible ? 'justify-content-between' : 'justify-content-end'">
              <p-button  *ngIf="backArrowVisible" class="float-left nextArrow" icon="pi pi-arrow-left" [label]=" 'Back' " [rounded]="true" [text]="true"
              [disabled]="disableStandardPhoto || disableWalkAroundBtn || damageNoType" (click)="onDetailsBeforeCard()" />
              <p-button *ngIf="nextArrowvisible == false" class="float-right nextArrow" icon="pi pi-arrow-right" [label]=" 'Next' " [iconPos]=" 'right' "  [text]="true"
              [disabled]="disableStandardPhoto || disableWalkAroundBtn || damageNoType" (click)="onDetailsNextCard()" />
              <button *ngIf="nextArrowvisible" type="submit" style="margin-top: 10px;" class="float-right btn btn-yellow btn-xs btn-sm btn-md btn-width p-1 border-0 walkpadding"
              (click)="onWalkAroundDone()">Walk-Around Done</button>
            </div>
            <app-alert [type]="'danger'" *ngIf="nextArrowvisible"
            [message]="inspectionUtilsService.getWalkAroundDoneErrorMessgae()"
              [duration]="2000" [icon]="'#exclamation-triangle-fill'" [visible]="noStandardImage">
           </app-alert>
            <div class="d-flex align-items-center justify-content-center">
              <div id="wireframeId{{inspectionUtilsService.selectedInspectionCardDetails.wireframeId}}" class="wireframeContainer ng-scope penskeHiglightSection"
                (click)="wireFrameClick($event)">
                <div #wireframeSvg [innerHTML]="inspectionUtilsService.wireFrameImg"></div>
                <div class="standard-marker-container" *ngIf="selectedDamage !== 'damagePhoto'" >
                <i class="pi pi-map-marker" [id]="marker.id" *ngFor="let marker of inspectionUtilsService.getStandardMarkers()[inspectionUtilsService.selectedInspectionCardDetails.wireframeId];index as i" [ngStyle]="{'color': marker.color, 'top': marker.positionY+'%', 'left': marker.positionX+'%'}" style="position: absolute;width: 10px;height: 10px;">{{marker.number}}</i>
                </div>
                <div class="marker-container" *ngIf="selectedDamage == 'damagePhoto'" >
                  <i class="pi pi-map-marker" [id]="marker.id" *ngFor="let marker of inspectionUtilsService.getDamageMarkers()[inspectionUtilsService.selectedInspectionCardDetails.wireframeId];index as i" [ngStyle]="{'color': marker.color, 'top': marker.positionY+'%', 'left': marker.positionX+'%','background-color':marker?.showNewDamageField?'red':'none', 'border-top-left-radius':marker?.showNewDamageField?'10px':'none'}" style="position: absolute;width: 10px;height: 10px;">{{marker.number}}</i>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="textAlignCenter">
              <span class="blue-color"><b>Report Types</b></span><br>
            </div>
            <div class="row">
              <span class="textAlignCenter mt-2">
                <p-radioButton name="standardPhoto" value="standardPhoto" [(ngModel)]="selectedDamage" inputId="standardPhoto" (onClick)="reportTypes('standardPhoto')"  [disabled]="disableStandardPhoto || disableWalkAroundBtn || damageNoType" (ngModelChange)="notifyParent()"/>
                <label for="standardPhoto" class="ms-2">Standard Photos</label>
              </span>
                <span class="textAlignCenter mt-2">
                <p-radioButton name="damagePhoto" value="damagePhoto" [(ngModel)]="selectedDamage" inputId="damagePhoto" (onClick)="reportTypes('damagePhoto')"  (ngModelChange)="notifyParent()"/>
                <label for="damagePhoto" class="ms-2">Damage Photos</label>
              </span>
            </div>

          </div>
        </div>

        <!-- Standard photo section starts -->
        <div *ngIf="selectedDamage=='standardPhoto'" class="flex flex-wrap gap-3 justify-content-center displayGrid textAlignCenter mt-4">
          <span class="fw-bold">Photos</span>
          <div class="card mb-2">
            <div class="card-body">
              <i class="pi pi-camera blue-color fw-bold margin-right-1P"></i>
              <button type="button" class="btn whiteButton  margin-top-2MP" data-bs-toggle="modal" data-bs-target="#cameraModal">
                {{ inspectionUtilsService.selectedMarker?.imageSrc ? '' : 'Take Photo' }}
              </button>
              <span *ngIf="inspectionUtilsService.selectedMarker.imageSrc">
                <p-image src="{{inspectionUtilsService.selectedMarker.imageSrc}}" alt="Image" width="76" [preview]="true"></p-image>
                <i class="pi pi-pencil ms-1" data-bs-toggle="modal" data-bs-target="#cameraModal" (click)="onEditInspImg()"></i>
              </span>
              <button type="button" class="btn whiteButton float-right" [ngClass]="inspectionUtilsService.selectedMarker.imageSrc ? 'padding8P' : ''" (click)="toggleComment()">
                <i class="pi pi-comment"></i>
              </button>
            </div>
          </div>
          <div *ngIf="showComment">
            <span class="fw-bold">Comments</span>
            <textarea class="mb-2 w-100" id="comments" name="comments" rows="4" cols="38" [(ngModel)]="inspectionUtilsService.selectedMarker.comments"></textarea>
          </div>
          <div class="d-flex align-items-center" *ngIf="getCurrStandardMarkers()?.length">
            <button class="btn btn-primary" (click)="previousDamage()" [disabled]="inspectionUtilsService.currentFrameMarkerCounter === 0 || disableWalkAroundBtn">
              <i class="pi pi-step-backward"></i>
            </button>
              <span class="mx-3">Standard photos({{ inspectionUtilsService.selectedMarker.number}})</span>
            <button class="btn btn-primary" (click)="nextDamage()" [disabled]="disableWalkAroundBtn">
              <i class="pi pi-step-forward"></i>
            </button>
            <button class="btn btn-danger ms-4" data-bs-toggle="modal" data-bs-target="#deleteMarkerModal"
              [disabled]="disableWalkAroundBtn || damageNoType">
              <i class="bi bi-trash"></i> Delete
            </button>
          </div>
        </div>
        <!-- Standard photo section ends -->

        <!-- Damage photo section starts-->
        <div *ngIf="selectedDamage === 'damagePhoto'" class="flex flex-wrap gap-3 justify-content-center displayGrid textAlignCenter mt-4">
          <div>
            <app-alert [type]="'danger'"
                       [message]="'Please select a damage type!!'"
                       [duration]="-1" [icon]="'#exclamation-triangle-fill'" [visible]="damageNoType">
            </app-alert>
            <app-alert [type]="'danger'"
                       [message]="'Photo is required to the Damage '+inspectionUtilsService.selectedMarker?.number"
                       [duration]="-1" [icon]="'#exclamation-triangle-fill'" [visible]="disableWalkAroundBtn">
            </app-alert>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <span class="fw-bold blue-color width-50">Damage Types</span>
            <div class="d-flex align-items-center blue-color cursor-pointer width-50" (click)="inspectionUtilsService.showDefinitions()"><span class="fw-medium mx-1">Definitions </span><i class="pi pi-external-link"></i></div>
          </div>

          <table class="table table-borderless" id="damageTypeSection">
            <tbody>
            <tr>
              <td class="p-1">
                    <span class="d-flex align-items-center">
                      <p-radioButton name="dent" value="dent" [(ngModel)]="damageType" inputId="dent" (click)="updateDamageType(damageType)" />
                      <label for="dent" class="ms-2">Dent</label>
                    </span>
              </td>
              <td class="p-1">
                <span class="d-flex align-items-center">
                  <p-radioButton name="chip" value="chip" [(ngModel)]="damageType" inputId="chip" (click)="updateDamageType(damageType)" />
                  <label for="chip" class="ms-2">Chip</label>
                </span>
          </td>
            </tr>
            <tr>
              <td class="p-1">
                    <span class="d-flex align-items-center">
                      <p-radioButton name="bend" value="bend" [(ngModel)]="damageType" inputId="bend" (click)="updateDamageType(damageType)" />
                      <label for="bend" class="ms-2">Bend</label>
                    </span>
              </td>
              <td class="p-1">
                <span class="d-flex align-items-center">
                  <p-radioButton name="missing" value="missing" [(ngModel)]="damageType" inputId="missing" (click)="updateDamageType(damageType)" />
                  <label for="missing" class="ms-2">Missing</label>
                </span>
              </td>
            </tr>
            <tr>
              <td class="p-1">
                      <span class="d-flex align-items-center">
                        <p-radioButton name="hole" value="hole" [(ngModel)]="damageType" inputId="hole" (click)="updateDamageType(damageType)" />
                        <label for="hole" class="ms-2">Hole</label>
                      </span>
              </td>
              <td class="p-1">
                <span class="d-flex align-items-center">
                  <p-radioButton name="crack" value="crack" [(ngModel)]="damageType" inputId="crack" (click)="updateDamageType(damageType)" />
                  <label for="crack" class="ms-2">Crack</label>
                </span>
              </td>
            </tr>
            <tr>
              <td class="p-1">
                      <span class="d-flex align-items-center">
                        <p-radioButton name="scratch" value="scratch" [(ngModel)]="damageType" inputId="scratch" (click)="updateDamageType(damageType)" />
                        <label for="scratch" class="ms-2">Scratch</label>
                      </span>
              </td>
              <td colspan="2" class="p-1">
                      <span class="d-flex align-items-center">
                        <p-radioButton name="stained" value="stained" [(ngModel)]="damageType" (click)="updateDamageType(damageType)" inputId="stained" />
                      <label for="stained" class="ms-2">Stains</label>
                    </span>
              </td>
            </tr>
            <tr>
              <td class="p-1">
                    <span class="d-flex align-items-center">
                    <p-checkbox [(ngModel)]="majorDamage" [binary]="true" inputId="majorDamage" />
                  <label for="majorDamage" class="ms-2">Major</label>
                  </span>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="d-flex align-items-center mt-3" *ngIf="getCurrDamageMarkers()?.length">
            <button class="btn btn-primary" (click)="previousDamage()" [disabled]="inspectionUtilsService.currentFrameMarkerCounter === 0 || disableWalkAroundBtn || damageNoType">
              <i class="pi pi-step-backward"></i>
            </button>
              <span class="mx-3">Damage({{ inspectionUtilsService.selectedMarker.number}})</span>
            <button class="btn btn-primary" (click)="nextDamage()" [disabled]="disableWalkAroundBtn || damageNoType">
              <i class="pi pi-step-forward"></i>
            </button>
            <button class="btn btn-danger ms-4" data-bs-toggle="modal" data-bs-target="#deleteMarkerModal">
              <i class="bi bi-trash"></i> Delete
            </button>
          </div>
        </div>
        <div *ngIf="selectedDamage=='damagePhoto'" class="flex flex-wrap gap-3 justify-content-center displayGrid textAlignCenter mt-4">
          <span class="fw-bold blue-color">Photos</span>
          <div class="card mb-2"  id="takePhotoSection">
            <div class="card-body">
              <i class="pi pi-camera blue-color fw-bold margin-right-1P"></i>
              <button type="button" class="btn whiteButton margin-top-2MP"  [disabled]="damageNoType" data-bs-toggle="modal" data-bs-target="#cameraModal">
                {{ inspectionUtilsService.selectedMarker?.imageSrc ? '' : 'Take Photo' }}
              </button>
              <span *ngIf="inspectionUtilsService.selectedMarker.imageSrc">
                <p-image src="{{inspectionUtilsService.selectedMarker.imageSrc}}" alt="Image" width="76" [preview]="true"></p-image>
                <i class="pi pi-pencil ms-1" data-bs-toggle="modal" data-bs-target="#cameraModal" (click)="onEditInspImg()"></i>
              </span>
            </div>
          </div>
          <div class="fw-bold blue-color mt-4">Comments</div>
          <textarea class="mb-2 w-100" id="comments" name="comments" rows="4" cols="50" [(ngModel)]="inspectionUtilsService.selectedMarker.comments"></textarea>
        </div>
        <!-- Damage Photo sections ends -->

      </div>
    </div>
  </div>
</div>


<div class="col-12 flex-container">
  <div class="shadow-lg p-3 mb-5 bg-white rounded col-12">
    <div class="form-group textAlignCenter">
      <app-alert [type]="'danger'" *ngIf="noStandardImage"
      [message]="inspectionUtilsService.getWalkAroundDoneErrorMessgae()"
                 [duration]="2000" [icon]="'#exclamation-triangle-fill'" (closeAlertEvent) ="closeAlert($event)" [visible]="noStandardImage">
      </app-alert>
      <button type="submit" class="btn btn-yellow btn-xs btn-sm btn-md btn-width" (click)="onWalkAroundDone()">Walk-Around Done</button>
    </div>
  </div>
</div>
