import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Data, Router} from "@angular/router";
import {UnitRemarketingStatus} from "../../interfaces/unit-remarketing-status.model";
import { RemarketingAlertsComponent } from '../remarketing-alerts/remarketing-alerts.component';
import { UnitSearchComponent } from '../unit-search/unit-search.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports:[RemarketingAlertsComponent, UnitSearchComponent],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  remarketingStatus: UnitRemarketingStatus = {
      unitNumber: '',
      unitHasImages: false,
      isUnitSalePending: false,
      isUnitListedToSell: false,
      havePicturesBeenRequested: false,
  };

  ngOnInit() {
    this.fetchRemarketingStatus();
  }

  constructor(private activatedRoute: ActivatedRoute) {
  }
  fetchRemarketingStatus() {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.remarketingStatus = data['remarketingStatus']?.data;
    });
  }
}
