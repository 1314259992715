import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment'
import {UnitRemarketingStatus} from "../../interfaces/unit-remarketing-status.model";

@Injectable({
  providedIn: 'root'
})
export class UnitService {
  private apiUrl = environment.backend_url;

  constructor(private http: HttpClient) { }

  getVehicleData(unitNumber: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/search/rabyunit?unitNumber=${unitNumber}`);
  }

  getLatestReport(unitNumber: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/inspection/getlatestreport/${unitNumber}`);
  }

  getUnitRemarketingStatus(unitNumber: string): Observable<UnitRemarketingStatus> {
    return this.http.get<UnitRemarketingStatus>(`${this.apiUrl}/modal/unit/remarketing-status/${unitNumber}`);
  }

  isUnitSalePending(unitNumber: string): Observable<boolean> {
    return this.http.get<any>(`${this.apiUrl}/modal/unit/sale-pending/${unitNumber}`);
  }

  isUnitListedToSell(unitNumber: string): Observable<boolean> {
    return this.http.get<any>(`${this.apiUrl}/unit/queued-to-sell/${unitNumber}`);
  }

  isUnitHasImages(unitNumber: string): Observable<boolean> {
    return this.http.get<any>(`${this.apiUrl}/unit/has-images/${unitNumber}`);
  }
}
