import { APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter , withHashLocation} from '@angular/router';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { CommonService } from './services/common/common.service';
import { environment } from 'src/environments/environment';
import { LocalAuthInterceptor } from './interceptors/http-interceptors/local-auth-interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';

function initializeKeycloak(keycloak: KeycloakService, commonService: CommonService) {
  return () => {
    return keycloak.init({
      config: {
        url: environment.auth_server_base_url,
        realm: 'ocpsso',
        clientId: 'rental-inspection-ui'
      },
      initOptions: {
        onLoad: 'login-required',
        pkceMethod: 'S256'
      }
    }).then(auth => {
      if (auth && keycloak.getKeycloakInstance().idTokenParsed!=undefined) {
        const username = keycloak.getKeycloakInstance().idTokenParsed?.['name'] as string;
        commonService.setUsername(username);
        commonService.setSsoId(keycloak.getKeycloakInstance().idTokenParsed?.['sub'] as string)
        console.log("token", username);
      }
    }).catch(e=>{
      console.log('error occured :',e)
    });
  };
}

//Determine whether to use actual Keycloak integration or just dummy auth, based on whether an auth server URL is set
console.log("environment.auth_server_base_url : ",environment.auth_server_base_url)
const authProvider = environment.auth_server_base_url === undefined
  ? { provide: HTTP_INTERCEPTORS, useClass: LocalAuthInterceptor, multi: true }
  : [
    { provide: APP_INITIALIZER, useFactory: initializeKeycloak, deps: [KeycloakService, CommonService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LocalAuthInterceptor, multi: true }
  ];

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(routes, withHashLocation()),
    provideHttpClient(withInterceptorsFromDi()),
    authProvider,
    KeycloakService,
    provideAnimations()
  ]
};
