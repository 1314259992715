import { Component, EventEmitter, Output  } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-contrast-switch',
  standalone: true,
  templateUrl: './contrast-switch.component.html',
  styleUrl: './contrast-switch.component.scss'
})
export class ContrastSwitchComponent {
    highContrast: boolean = false;
    @Output() highContrastChange = new EventEmitter<boolean>();

    constructor(private commonService: CommonService) {}

    ngOnInit(): void {
      const username = this.commonService.getUsername();
      if (username) {
        const highContrastCookie = this.commonService.getCookie(`highContrast${username}`);
        this.highContrast = highContrastCookie === 'true';
      }
    }

    toggleHighContrast() {
      this.highContrast = !this.highContrast;
      this.highContrastChange.emit(this.highContrast);

      const username = this.commonService.getUsername();
      if (username != '') {
        this.commonService.deleteCookie(`highContrast${username}`);
        this.commonService.setCookie(`highContrast${username}`, this.highContrast.toString(), 365);
      }
    }
}
