export class UnitRemarketingCheck {

  get unitNumber(): string {
    return this._unitNumber;
  }

  set unitNumber(value: string) {
    this._unitNumber = value;
  }

  get performRemarketingCheck(): boolean {
    return this._performRemarketingCheck;
  }

  set performRemarketingCheck(value: boolean) {
    this._performRemarketingCheck = value;
  }

  private _performRemarketingCheck: boolean = false;
  private _unitNumber: string= '';
}
