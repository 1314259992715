import { Injectable } from "@angular/core"
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { catchError, from, Observable, switchMap, throwError } from "rxjs";
import { KeycloakService } from "keycloak-angular";

@Injectable()
export class LocalAuthInterceptor implements HttpInterceptor {
    constructor(private keycloakService: KeycloakService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.keycloakService.getToken()).pipe(
          switchMap((token) => {
            const clonedRequest = req.clone({setHeaders: { Authorization: `Bearer ${token}`}});
            return next.handle(clonedRequest);
          }),
          catchError((error) => {
            // Handle token retrieval error

            //Change this SSOID to whatever user you want to appear as
            const ssoid = '600555555';
            const authReq = req.clone({ setHeaders: {Authorization: 'Bearer ' + ssoid}});
            return next.handle(authReq);
          })
        );
      }    
}
