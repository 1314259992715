<div class="change-location w-md-45">
  <div class="change-location-container px-md-4 highContrast" >
    <div id="unitSearchText" class="change-location-header text-md-center p-4">
      <h2 class="blue-color">Change Location</h2>
    </div>
    <div class="shadow mb-3 bg-white p-4">
      <div class="py-4">
        <h6 class="floatLeft mb-3">Location Number</h6>
        <div class="d-flex align-items-center justify-content-center">
          <div class="form-group p-float-label">
            <input pInputText id="deviceLocationId" type="text" class="form-control" maxlength="7" pattern="^\d{4}-\d{2}$" required
                 [(ngModel)]="deviceLocationId" (ngModelChange)="onInputChange()" #locationInput>
            <label class="form-check-label fs-14px fw-medium"  *ngIf="!isInvalid">0000-00 (Format)</label>
            <label class="form-check-label fs-14px fw-medium"  *ngIf="isInvalid && hasInteracted">Expected Format 0000-00</label>
          </div>
            <button class="btn fw-medium fs-14px mx-2 fw-medium" (click)="onCancel()">Cancel</button>
            <button class="btn fw-medium fs-14px" [ngClass]="{'save-btn': !isInvalid}" (click)="onLocationEnter()" [disabled]="isInvalid">Save</button>
        </div>
        <div *ngIf="isInvalid && hasInteracted" class="invalid-feedback fw-medium">
          Enter Expected Numeric Format
        </div>
        
      </div>
    </div>
  </div>
  

</div>

