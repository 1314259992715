
<div *ngIf="showRemarketingAlerts()">
  <p-dialog [visible]="showRemarketingAlerts()" (visibleChange)="resetRemarketingStatus()" [style]="{width: '35vw'}" [baseZIndex]="10000">
    <p-header><span *ngIf="remarketingStatus?.isUnitSalePending else noPicsTitle">Sale Pending</span> <ng-template #noPicsTitle><span> No Pictures</span> </ng-template></p-header>
    <div *ngIf="remarketingStatus?.isUnitSalePending else noPicturesMsg">
      <div>Unit #{{remarketingStatus.unitNumber}} is in the process of being sold.</div>
      <div>Please lock down the unit and change</div>
      <div>the RentalNet status to Sale Pending.</div>
    </div>
    <ng-template #noPicturesMsg>
    <div *ngIf="showNoPicturesMessage()">
      <div>This unit has been identified to sell and no pictures</div>
      <div>exist. Please use the Used Trucks Photo Upload app to</div>
      <div>upload images for this unit.</div>
    </div>
    </ng-template>
    <p-footer>
      <button class="btn pull-right btnActive" (click)="resetRemarketingStatus()">Close</button>
    </p-footer>
  </p-dialog>
</div>
