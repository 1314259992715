import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PdfService } from '../../services/pdf/pdf.service';
import { CommonService } from '../../services/common/common.service';
import { InspectionDetailsService } from '../../services/inspection-details/inspection-details.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../loader/loader.component';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';

interface reportHeader {
  reportDate: string,
  siginingDriver: string,
  customerName: string,
  raNumber: string
}

@Component({
  selector: 'app-inspection-summary',
  standalone: true,
  imports:[CommonModule, ReactiveFormsModule, LoaderComponent, FloatLabelModule, InputTextModule],
  templateUrl: './inspection-summary.component.html',
  styleUrls: ['./inspection-summary.component.scss']
})
export class InspectionSummaryComponent implements OnInit {
  public showLoader: boolean = false;
  public summaryForm: FormGroup = new FormGroup({});
  public saveReportPayload: any;
  public inputsDisabled: boolean = false;
  private apiUrl = environment.backend_url;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private pdfService: PdfService,
    public commonService: CommonService,
    private inspDetailsService: InspectionDetailsService,
    private http: HttpClient
  ) { }
  ngOnInit(): void {
    this.summaryForm = this.fb.group({
      emailNotRequired: [false],
      to: [{ value: '', disabled: this.inputsDisabled }, [Validators.required, Validators.email]],
      cc: [{ value: '', disabled: this.inputsDisabled }, [Validators.email]],
    })
    this.handleEmailAddress();
    this.summaryForm.get('emailNotRequired')?.valueChanges.subscribe(value => {
      if (value) {
        this.summaryForm.get('to')?.reset();
      } else {
        this.handleEmailAddress();
      }
    });
  }

  handleEmailAddress() {
    if (this.commonService.getFinalReportData().emailAddress) {
      this.summaryForm.get('to')?.patchValue(this.commonService.getFinalReportData().emailAddress);
    }
    if (this.commonService.getFinalReportData().ccEmailAddress) {
      this.summaryForm.get('cc')?.patchValue(this.commonService.getFinalReportData().ccEmailAddress);
    }
  }

  onEdit() {
    this.router.navigate(['/home']);
  }

  onFinalizeReport() {
    this.showLoader = true;
    this.saveReportPayload = this.commonService.getFinalReportData();
    this.saveReportPayload.inspectionEndTimeInMilliseconds = Date.now();
    if (this.summaryForm.get('emailNotRequired')?.value) {
      this.summaryForm.reset();
      this.saveReportPayload.sendEmail = false;
      this.saveReportPayload.emailAddress = this.saveReportPayload.ccEmailAddress = null;
    } else {
      this.saveReportPayload.emailAddress = this.summaryForm.get('to')?.value.trim();
      if (this.summaryForm.get('cc')?.value?.trim().length) {
        this.saveReportPayload.ccEmailAddress = this.summaryForm.get('cc')?.value.trim();
      }
      this.saveReportPayload.sendEmail = true;
    }

    let finalDamageList = this.saveReportPayload.damageList[this.saveReportPayload.unitNumber];
    if (finalDamageList?.length) {
      let postArray: any = [];
      let newCheckInDamagesArr:any=[];
      let newCheckOutDamagesArr:any=[];
      finalDamageList.forEach((damageList: any) => {
        if (damageList && !damageList.imageId) {
          let formData = new FormData();
          if(this.saveReportPayload.inspectionType === 'IN') newCheckInDamagesArr.push(damageList);
          if(this.saveReportPayload.inspectionType === 'OUT') newCheckOutDamagesArr.push(damageList);
          formData.append('file', this.inspDetailsService.base64ImageToBlob(damageList.imageSrc));
          postArray.push(this.http.post<any>(`${this.apiUrl}/inspection/imageupload/${this.saveReportPayload.unitNumber}`, formData));
        }
      });
      if(!postArray.length){
        this.handleSaveReport(); // To call save report when no damages are added during check-in.
      }

      forkJoin(postArray).subscribe({
        next:(results: any) => {
          if (results && results.length > 0){
            results.forEach((result: any, index: number) => {
              if(this.saveReportPayload.inspectionType === 'OUT' && !newCheckOutDamagesArr[index]?.imageId){
                newCheckOutDamagesArr[index].imageId = result.data;
              }else if(this.saveReportPayload.inspectionType === 'IN' && !newCheckInDamagesArr[index]?.imageId){
                newCheckInDamagesArr[index].imageId = result.data; 
              }
            });
          }
          this.handleSaveReport();
        },
        error:(e=>{
          console.log('Error occured while uplaoding the images : ',e);
          this.showLoader = false;
        })
      });
    }
  }

  getCurrentData() {
    return new Date();
  }

  handleSaveReport(){
    delete this.saveReportPayload.vehicleInfo;
    this.pdfService.saveReport(this.saveReportPayload).subscribe({
      next: (reportData) => {
        console.log("save report res: ", reportData)
        if (!this.commonService.getFinalReportData()?.customerPresent) {
          this.commonService.isInspectionComplete = true;
          this.router.navigate(['home']);
        } else {
          this.handlePdfDownload(reportData.data);
        }
      },
      error: (e) => {
        console.log("Error occured while saving the report: ", e)
        this.showLoader = false;
      }
    });
  }

  handlePdfDownload(inspectionId: string) {
    this.pdfService.downloadPdf(inspectionId).subscribe({
      next: (res: any) => {
        this.commonService.pdfData = {
          id: inspectionId,
          link: this.pdfService.handlePdfResponse(res)
        };
        this.showLoader = false;
        this.router.navigate(['agreement']);
      }, error: (e) => {
        console.log('Error occured while fetching the pdf :', e);
        this.showLoader = false;
      }
    })
  }

}
