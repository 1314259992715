<!-- Camera Modal -->
<div class="modal fade" id="cameraModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Take a picture</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeCamera()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="camera-body">
          <div id="camera-container">
            <video id="video" #videoElement autoplay playsinline muted></video>
            <canvas id="overlay"></canvas>
            <canvas id="photo-canvas" #canvasElement style="display: none;"></canvas>

            <button id="capture-button" class="p-2 rounded fw-medium" (click)="capturePhoto()">Capture Photo</button>

            <div class="zoom-controls">
              <button class="zoom-btn" title="Zoom In" (click)="zoomIn()" *ngIf="detectOS()">
                <i class="pi pi-search-plus"></i>
              </button>
              <button class="zoom-btn" title="Zoom Out" (click)="zoomOut()" *ngIf="detectOS()">
                <i class="pi pi-search-minus"></i>
              </button>
              <button class="zoom-btn" title="Reset" (click)="resetZoom()" *ngIf="detectOS()">
                <i class="pi pi-refresh"></i>
              </button>
              <div class="d-flex align-items-center justify-content-between">
                <input type="checkbox" class="form-check-input" [(ngModel)]="imgResolutionHigh" id="resolutionToggle" (click)="toggleImageResolution()" >
                <label class="form-check-label bg-light mx-1 p-1 fw-medium rounded fs-14px" for="resolutionToggle">High Resolution</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button id="modalCloseButton" type="button" class="btn btn-secondary" (click)="closeCamera()" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
