import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot} from "@angular/router";
import {UnitService} from "../unit/unit.service";
import {UnitRemarketingCheck} from "../../models/unit-remarketing-check";
import {Observable, of} from "rxjs";
import {UnitRemarketingStatus} from "../../interfaces/unit-remarketing-status.model";
import {REMARKETING_CHECK} from "../../constants/app-constants";

export const homeComponentResolver: ResolveFn<Observable<UnitRemarketingStatus> | Observable<null>> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const unitRemarketing: UnitRemarketingCheck = inject(Router).getCurrentNavigation()?.extras?.state?.[REMARKETING_CHECK];
  if(unitRemarketing?.performRemarketingCheck && unitRemarketing.unitNumber) {
    return inject(UnitService).getUnitRemarketingStatus(unitRemarketing['unitNumber']);
  }
  else {
    return of(null);
  }
};
@Injectable({
  providedIn: 'root'
})

export class HomeComponentResolverService {
  constructor() { }
}
