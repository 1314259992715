import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports:[CommonModule],
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  @Input() type: 'success' | 'danger' | 'warning' | 'info' = 'info';
  @Input() message: string = '';
  @Input() duration: number = 5000; // Default duration of 5 seconds. Note: when the duration is -1, the alert message stays forever
  @Input() icon: string = '';
  @Input() visible: boolean = false;
  @Output() closeAlertEvent = new EventEmitter<boolean>();


  ngOnChanges(changes: SimpleChanges): void {
      if (changes['visible'] && this.visible) {
        (this.duration == -1)? null : this.autoHide();
      }
    }

  private autoHide(): void {
      setTimeout(() => {
        this.closeAlert()
      }, this.duration);
    }

    closeAlert() {
        this.visible = false;
        this.closeAlertEvent.emit(this.visible);
      }
}
