import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd, RouterOutlet, RouterLink } from '@angular/router';
import { RouteService } from './services/route/route.service';
import { CommonService } from 'src/app/services/common/common.service';
import { FooterComponent } from './components/footer/footer.component';
import { ContrastSwitchComponent } from './components/contrast-switch/contrast-switch.component';
import { HeaderComponent } from './components/header/header.component';
import { CommonModule } from '@angular/common';

interface WidgetResponse {
  ssoid: string,
  widgets: String[]
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,RouterLink,FooterComponent, ContrastSwitchComponent, HeaderComponent, CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  authServerUrl = environment.auth_server_base_url || "Not Used";
  backendServerUrl = environment.backend_url;
  ssoid = '';
  widgets: String[] = [];
  isHomeRoute: boolean = true;
  isHistoryRoute: boolean = true;
  isChangeLocationRoute: boolean = false;
  highContrast: boolean = false;

  constructor(private http: HttpClient, private router: Router, private routeService: RouteService, private cdr: ChangeDetectorRef, private commonService: CommonService) {
    this.http.get<WidgetResponse>(environment.backend_url + "/widget/list")
      .subscribe(data => {
        this.ssoid = data.ssoid;
        this.widgets = data.widgets;
      });
  };

   ngOnInit() {
      this.routeService.getHomeRoute().subscribe(isHomeRoute => {
        this.isHomeRoute = isHomeRoute;
        this.cdr.detectChanges();  /* As the isHomeRoute is not setting on html running detection manually */
      });
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.isHomeRoute = event.urlAfterRedirects.includes('/home') || event.urlAfterRedirects === '/'; /* finding the home route  */
          this.isChangeLocationRoute = event.urlAfterRedirects === '/location'; /* finding the change location route  */
          this.isHistoryRoute = event.urlAfterRedirects.startsWith('/history'); /* finding the history route  */
          this.routeService.setHomeRoute(this.isHomeRoute);
          if(!this.commonService.getFinalReportData()?.unitNumber && !this.isChangeLocationRoute && !this.isHistoryRoute){
            this.router.navigate(['/home']);
          }
          this.scrollToTop();
        }
      });

      const username = this.commonService.getUsername();
      if (username) {
        const highContrastCookie = this.commonService.getCookie(`highContrast${username}`);
        this.highContrast = highContrastCookie === 'true';
        this.applyHighContrast();
      }
    }

    onHighContrastChange(highContrast: boolean) {
      this.highContrast = highContrast;
      this.applyHighContrast();
    }

   goToHome() {
      this.router.navigate(['/home']);
    }

    applyHighContrast(): void {
      if (this.highContrast) {
        document.body.classList.add('high-contrast');
      } else {
        document.body.classList.remove('high-contrast');
      }

      const username = this.commonService.getUsername();
      if (username) {
        this.commonService.deleteCookie(`highContrast${username}`);
        this.commonService.setCookie(`highContrast${username}`, this.highContrast.toString(), 365);
      }
    }

    scrollToTop(){
      window.scrollTo({top:0, behavior:'smooth'});
    }
}
