import { ElementRef, Injectable } from '@angular/core';
import { inspectionType } from '../../interfaces/inspectionType';
import { CommonService } from '../common/common.service';
import { SafeHtml } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';
import { InspectionDetailsService } from '../inspection-details/inspection-details.service';
import definitions from '../../../assets/data/definitions.json';

@Injectable({
  providedIn: 'root'
})
export class InspectionUtilsService {
  private _vehicleData: any;
  private _vehicleWireframeData: any[] = [];
  private _latestReportData: any;
  public standardMarkers:any = {};
  public damageMarkers:any = {};
  private _selectedMarker: any = {};
  private _currentFrameMarkerCounter: number = 0;
  private _markerComments: string='';
  private _selectedInspectionCardDetails: any = {};
  private _wireFrameImg?: SafeHtml | undefined;
  private _vehicleImageEl!: ElementRef<HTMLImageElement>;
  public damageTypes:any[] = ['isDent','isBend','isHole','isCrack','isChip','isMissing','isScratch', 'isStained', 'isMajor'];
  public definitionsData:any = definitions.damageTypes;
  public showDefinitionsDialog:boolean = false;

  constructor(private commonService: CommonService, private inspDetailsService : InspectionDetailsService) { }

setStandardMarkers(wireframeId:string, marker:any){
  if(!this.standardMarkers[wireframeId]){
    this.standardMarkers[wireframeId] = [];
  }
  if(!marker.id){
    marker.id = `standard-marker-${this.standardMarkers[wireframeId].length+1}`;
  }
  if(!marker.number){
    marker.number = this.standardMarkers[wireframeId].length+1;
  }
  this.standardMarkers[wireframeId].push(marker);
}

getStandardMarkers(){
  return this.standardMarkers;
}

setDamageMarkers(wireframeId:string, marker:any){
  if(!this.damageMarkers[wireframeId]){
    this.damageMarkers[wireframeId] = [];
  }
  if(!marker.id){
    marker.id = `marker-${this.damageMarkers[wireframeId].length+1}`;
  }
  if(!marker.damageType){
    marker.damageType = this.getDamageTypeProperty(marker);
  }
  if(!marker.number){
    marker.number = this.damageMarkers[wireframeId].length+1;
  }
  this.damageMarkers[wireframeId].push(marker);
}

getDamageMarkers(){
  return this.damageMarkers;
}

getCheckOutDamageList(){
  return this.commonService.getFinalReportData().damageList[this.commonService.getFinalReportData().unitNumber] || [];
}

  /* --Getter and Setters-- */

  public get vehicleData(): any {
    return this._vehicleData;
  }
  public set vehicleData(value: any) {
    this._vehicleData = value;
  }

  public get vehicleWireframeData(): any[] {
    return this._vehicleWireframeData;
  }
  public set vehicleWireframeData(value: any[]) {
    this._vehicleWireframeData = value;
  }

  public get latestReportData(): any {
    return this._latestReportData;
  }
  public set latestReportData(value: any) {
    this._latestReportData = value;
  }

  public get selectedMarker(): any {
    return this._selectedMarker;
  }
  public set selectedMarker(value: any) {
    this._selectedMarker = value;
  }

  public get currentFrameMarkerCounter(): number {
    return this._currentFrameMarkerCounter;
  }
  public set currentFrameMarkerCounter(value: number) {
    this._currentFrameMarkerCounter = value;
  }

  public get markerComments(): string {
    return this._markerComments;
  }
  public set markerComments(value: string) {
    this._markerComments = value;
  }

  public get selectedInspectionCardDetails(): any {
    return this._selectedInspectionCardDetails;
  }
  public set selectedInspectionCardDetails(value: any) {
    this._selectedInspectionCardDetails = value;
  }

  public get wireFrameImg(): SafeHtml | undefined {
    return this._wireFrameImg;
  }
  public set wireFrameImg(value: SafeHtml | undefined) {
    this._wireFrameImg = value;
  }

  public get vehicleImageEl(): ElementRef<HTMLImageElement> {
    return this._vehicleImageEl;
  }
  public set vehicleImageEl(value: ElementRef<HTMLImageElement>) {
    this._vehicleImageEl = value;
  }


  // ----- Methods ----

  addStandardMarker(wireframeId:number){
    let standardMarkerCount;
    if (!this.standardMarkers[wireframeId]) {
      this.standardMarkers[wireframeId] = [];
    }
    if (this.standardMarkers[wireframeId].length) {
      standardMarkerCount = this.standardMarkers[wireframeId][this.standardMarkers[wireframeId].length - 1].number + 1;
    } else {
      standardMarkerCount = 1;
    }
    const markerTop = 95;
    const markerLeft = 10 + (standardMarkerCount*10);
    const newMarker = this.newMarkerObj(wireframeId,true, standardMarkerCount, markerTop, markerLeft, `standard-marker-${standardMarkerCount}`,'','#52911f', null, '')
    this.standardMarkers[wireframeId].push(newMarker);
    this._selectedMarker = newMarker;
    this._currentFrameMarkerCounter = this.standardMarkers[wireframeId].length;
  }

  addDamageMarker(wireframeId:number, event:any){
    let damageMarkerCount;
    if (!this.damageMarkers[wireframeId]) {
      this.damageMarkers[wireframeId] = [];
    }
    if (this.damageMarkers[wireframeId].length) {
      damageMarkerCount = this.damageMarkers[wireframeId][this.damageMarkers[wireframeId].length - 1].number + 1;
    } else {
      damageMarkerCount = 1;
    }
    const frameEl = this.vehicleImageEl.nativeElement.getBoundingClientRect();
    let x:number = 0;
    let y:number = 0;

    if (event.type && event.type.indexOf('touch') >= 0) {
      const touchEvent = event as TouchEvent;
      const touch = touchEvent.changedTouches[0];
      x = touch.clientX;
      y = touch.clientY;
    } else {
      const mouseEvent = event as MouseEvent;
      x = mouseEvent.clientX;
      y = mouseEvent.clientY;
    }
    const img = this.vehicleImageEl.nativeElement;
    const xDamageLocationPercent = (x - frameEl.left) / img.clientWidth * 100;
    const yDamageLocationPercent = (y - frameEl.top) / img.clientHeight * 100;
    const newMarker = this.newMarkerObj(wireframeId,false,damageMarkerCount, yDamageLocationPercent, xDamageLocationPercent, `marker-${damageMarkerCount}`,'','#002084', null, '');
    this.damageMarkers[wireframeId].push(newMarker);
    this._selectedMarker = newMarker;
    this._currentFrameMarkerCounter = this.damageMarkers[wireframeId].length;
  }

  newMarkerObj(wireframeId:number,isStandard:boolean, markerNumber:number, markerTop:number, markerLeft:number, id:string, comments:string,color:string, damageType?:any, imageSrc?:string){
    return {
      damageId: null,
      clientSideId: 0,
      wireframeId:wireframeId,
      imageFileItem:null,
      isStandard:isStandard,
      isDent:false,
      isBend:false,
      isHole:false,
      isCrack:false,
      isChip:false,
      isMissing:false,
      isScratch:false,
      isMajor:false,
      imageId:false,
      firstReportedIn:null,
      number: markerNumber,
      positionY:markerTop,
      positionX: markerLeft,
      id: id,
      comments: comments,
      color: color,
      damageType:damageType,
      imageSrc:imageSrc,
      isNewDamage: this.commonService.finalReportData.inspectionType === 'IN' ? true : false,
      showNewDamageField: this.commonService.finalReportData.inspectionType === 'IN' ? true : false,
    }
  }

  newWireframeObj(){
    return {
      number: 0,
      title: "",
      damage: "No",
      comments: "No",
      stdPhoto: "No",
      wireframeId: 0,
      svgData: "",
      active: false,
   }
  }

  getDamageTypeProperty(damage:any){
    const selectedDamage = this.damageTypes.find(type=> damage[`${type}`]==true)
    return selectedDamage?.replace('is','').toLowerCase();
  }

  resetDamageTypes(){
    this.damageTypes.forEach(type=>{this.selectedMarker[`${type}`]=false})
  }

  getTotalMarkerCount(selectedDamage:string, wireframeId:number){
    return selectedDamage == 'damagePhoto' ? this.damageMarkers[wireframeId].length : this.standardMarkers[wireframeId].length;
  }

  async getImageById(imageId: number): Promise<string | ArrayBuffer | null> {
    return await new Promise(async (resolve, reject) => {
     try {
       const res = await firstValueFrom(this.inspDetailsService.getImageById(imageId));
       const reader = new FileReader();
       reader.onloadend = () => {
         resolve(reader.result);
       };
       reader.onerror = (err) => reject(err);
       reader.readAsDataURL(res);
     } catch (err) {
       reject(err);
     }
   });
 }

  reValidateCardDetails(inspectionType: string) {
    const selectedWireframeStdMarkerList = this.getStandardMarkers()[this.selectedInspectionCardDetails.wireframeId];
    const selectedWireframeDamageMarkerList = this.getDamageMarkers()[this.selectedInspectionCardDetails.wireframeId];

    if (inspectionType == 'IN') {
      let newDamageMarkers = selectedWireframeDamageMarkerList?.find((marker: any) => marker.isNewDamage)
      let newStandardMarkers = selectedWireframeStdMarkerList?.find((marker: any) => marker.isNewDamage)

      this.selectedInspectionCardDetails.damage.IN = newDamageMarkers ? 'Yes' : 'No';
      this.selectedInspectionCardDetails.stdPhoto.IN = newStandardMarkers ? 'Yes' : 'No';
      this.selectedInspectionCardDetails.comments.IN = (selectedWireframeStdMarkerList?.some((marker: any) => marker.comments && marker.isNewDamage) 
                                                      || selectedWireframeDamageMarkerList?.some((marker: any) => marker.comments && marker.isNewDamage))
                                                       ? 'Yes' : 'No';
    } else {
      this.selectedInspectionCardDetails.damage.OUT = selectedWireframeDamageMarkerList?.length ? 'Yes' : 'No';
      this.selectedInspectionCardDetails.stdPhoto.OUT = selectedWireframeStdMarkerList?.length ? 'Yes' : 'No';
      this.selectedInspectionCardDetails.comments.OUT = (selectedWireframeStdMarkerList?.some((marker: any) => marker.comments && !marker.isNewDamage) 
                                                      || selectedWireframeDamageMarkerList?.some((marker: any) => marker.comments && !marker.isNewDamage))
                                                        ? 'Yes' : 'No';
    }
  }

  showDefinitions(){
    this.showDefinitionsDialog = true;
  }

  checkWireframeCardStatus(wireframeCard:any,type:string){
    if(!wireframeCard?.damage) return false;

    return wireframeCard.damage[type] == 'Yes'
      || wireframeCard.stdPhoto[type] == 'Yes' 
      || wireframeCard.comments[type] == 'Yes';
  }

  getWalkAroundDoneErrorMessgae(){
    return `Each wireframe in ${this.commonService.getFinalReportData().inspectionType == 'OUT' ? 'Check-Out' : 'Check-In'} must require a standard photo. Please attach to proceed!!`;
  }

}
