<div class="content">
  <div class="custom-styles">
    <div class="unit-search-header" [ngClass]="{'fixed-container': !!raByUnitData?.vehicleValidation?.unitNumber, 'custom-container': showLocationAlert}">
      <div class="unit-search-container textAlignCenter" id="unitSearchText" *ngIf="!raByUnitData?.vehicleValidation?.unitNumber">
        <h6 class="blue-color">Unit Number Search</h6>
        <span class="d-none d-md-block">
          <h4>Please enter a unit number, and then click Find Unit.</h4>
        </span>
        <span class="greyColor d-none d-md-block">Once located the Inspection Type section is enabled.</span>
        <span class="d-block d-md-none line-height-one-half">Please enter a unit number, and then click Find Unit. Once
          located the Inspection Type section is enabled.</span>
      </div>

      <div class="shadow p-3 bg-white w-md-45">
        <div class="textAlignWebkitCenter">
          <div *ngIf="locationDifferent">
            <app-alert  *ngIf="raNumber" [type]="'warning'" [message]="dynamicWarningMessage" [duration]="-1"
              [icon]="'#exclamation-triangle-fill'" [visible]="locationDifferent" (closeAlertEvent)="handleAlertClose($event)" >
            </app-alert>
          </div>
          <div *ngIf="this.commonService.isInspectionComplete">
            <app-alert [type]="'success'" [message]="inspectionCompleteMsg" [duration]="-1"
            [icon]="'#exclamation-triangle-fill'" [visible]="this.commonService.isInspectionComplete">
            </app-alert>
          </div>
          <div class="input-group custom-styles justify-content-center">
            <button type="button" class="btn" [ngClass]="[!isTabletOrBigger$ ? 'btn-md' : 'btn-lg', isNumAndLetter ? 'btn-primary' : 'btn-secondary']"  (click)="onClickNumAndLetter()">
              <i class="pi pi-language"></i>
            </button>
            <div class="p-float-label me-2" style="max-width: 50%;">
                <input id="unitNumber" pInputText type="number" class="form-control no-left-radius" [(ngModel)]="unitNumber" [ngClass]="unitNotFound ? 'border-danger' : ''" (keyup.enter)="findUnit()"  *ngIf="!isNumAndLetter" />
                <input id="unitNumber" pInputText type="text" class="form-control no-left-radius" [(ngModel)]="unitNumber" [ngClass]="unitNotFound ? 'border-danger' : ''" (keyup.enter)="findUnit()"  *ngIf="isNumAndLetter" />
                <label htmlFor="unitNumber">Enter Unit Number</label>
              </div>
            <div class="input-group-append">
              <button class="btn btn-yellow btn-sm btn-md fw-bold d-flex align-items-center" type="button" (click)="findUnit()"
                [disabled]="!unitNumber"><span class="mx-1">Find Unit</span><img src="assets/findUnit.svg"></button>
            </div>
          </div>
          <div class="justify-content-center display-flex" *ngIf="isNumAndLetter">
            <span class="blue-color marginLeft6MRem">**Letterpad ON</span>
          </div>
          <div class="d-flex align-items-center justify-content-around">
            <span class="red-color err-msg" *ngIf="unitNotFound">Unit number not found. Re-enter #</span>
            <span></span> <!-- just a place holder added for error msg alignment -->
          </div>
          <div *ngIf="unitNotFound">
            <app-alert [type]="'danger'" [message]="'Unit not be found.'" [duration]="2000"
              [icon]="'#exclamation-triangle-fill'" [visible]="unitNotFound">
            </app-alert>
          </div>
        </div>
      </div>
    </div>


    <div class="shadow p-3 mb-5 bg-white w-md-45" [ngStyle]="{'margin-top': showLocationAlert ? '11rem' : '4rem' }"
     [ngClass]="{'margin-top-4rem': !!raByUnitData?.vehicleValidation?.unitNumber }" *ngIf="raByUnitData?.vehicleValidation?.unitNumber">
      <div>
        <div class="custom-styles">
          <span class="section-card-title">Unit Number: {{raByUnitData?.vehicleValidation?.unitNumber}}</span>
        </div>
        <div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Description</th>
                <th scope="col">Year/ Make/ Model</th>
                <th scope="col">Color</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ raByUnitData.vehicleValidation.desc }}</td>
                <td>{{ raByUnitData.vehicleValidation.year }} {{ raByUnitData.vehicleValidation.model }} {{ raByUnitData.vehicleValidation.make }}</td>
                <td>{{ raByUnitData.vehicleValidation.color }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white w-md-45" *ngIf="raByUnitData.vehicleValidation?.unitNumber && !unitFoundNoRA">
      <div>
        <div class="custom-styles">
          <span class="section-card-title">Rental Agreement: {{newRAObjectData.raNumber}}</span>
        </div>
        <div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Customer Name</th>
                <th scope="col">Check-Out Date</th>
                <th scope="col">Towing Unit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ newRAObjectData?.customerName }}</td>
                <td>{{ newRAObjectData?.dateOut | date:'MM/dd/yyyy' }}</td>
                <td>{{ newRAObjectData?.secondaryUnitNumber || 'N/A' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <span class="fw-medium blue-color d-flex align-items-center fs-12px"
          [ngClass]="{'disable-text': !newRAObjectData.customerPresent}" (click)="onChangeRA()">
            <span style="text-decoration: underline; cursor: pointer;">Change RA</span><i class="pi pi-angle-right" style="font-size: 1.1rem"></i>
          </span>
        </div>
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white w-md-45" *ngIf="raByUnitData.vehicleValidation?.unitNumber && unitFoundNoRA">
      <!--<span class="red-color" *ngIf="unitFoundNoRA">Unit number found with no RA. Please create one to proceed </span>-->
      <div *ngIf="unitFoundNoRA && !changeRA">
        <app-alert [type]="'danger'" [message]="'Unit number found with no RA. Please create one below to proceed'"
          [icon]="'#exclamation-triangle-fill'" [duration]="-1" [visible]="unitFoundNoRA">
          <!-- Note: when the duration is -1, the alert message stays forever-->
        </app-alert>
      </div>
      <div>
        <div class="custom-styles mb-3">
          <span class="fw-bold p-2">Rental Agreement</span>
        </div>
        <form #contractForm="ngForm" id="contractForm">
          <div class="form-group p-float-label mt-2  marginBottom12P">
            <input pInputText type="text" class="form-control" [(ngModel)]="manualRA.raNumber" [ngModelOptions]="{standalone: true}" name="contractRANumber" [required]="!manualRA.customerPresent">
            <label [ngClass]="{'required-input':!manualRA.customerPresent}" class="fs-14px">Rental Agreement Number</label>
          </div>
          <div class="form-group p-float-label mt-2  marginBottom12P">
            <input pInputText type="text" class="form-control" [(ngModel)]="manualRA.secondaryUnitNumber" [ngModelOptions]="{standalone: true}" name="contractTowingNumber">
            <label class="fs-14px">Towing Number</label>
          </div>
          <div class="form-group p-float-label mt-2  marginBottom12P">
            <input pInputText type="text" class="form-control" [(ngModel)]="manualRA.signingCustomer" [ngModelOptions]="{standalone: true}" name="signingCustomerName" [required]="!manualRA.customerPresent">
            <label [ngClass]="{'required-input':!manualRA.customerPresent}" class="fs-14px">Signing Driver Name</label>
          </div>
          <div class="form-group p-float-label mt-2 marginBottom12P">
            <input pInputText type="email" class="form-control" [disabled]="manualRA.customerPresent"  [(ngModel)]="manualRA.emailAddress" [ngModelOptions]="{standalone: true}" name="customerEmail" email>
            <label class="fs-14px">Customer's Email Address</label>
            <div *ngIf="contractForm.controls['customerEmail']?.touched && contractForm.controls['customerEmail']?.invalid"
                class="invalid-feedback d-block">Invalid email format.</div>
          </div>
          <div class="form-group form-check marginBottom12P d-flex align-items-center p-0">
            <input type="checkbox" id="customerNotPresent" class="form-check-input mx-0" [(ngModel)]="manualRA.customerPresent" [value]="!manualRA.customerPresent" [ngModelOptions]="{standalone: true}" (click)="onClickCustomerNotpresentInChangeRA()" >
            <label class="form-check-label mx-1 fs-14px" for="customerNotPresent">Customer Not Present</label>
          </div>
          <div class="d-flex mt-3 p-2"
            [ngClass]="raByUnitData.vehicleValidation?.unitNumber && raByUnitData?.contractSearchResults?.transNumber ? ' justify-content-between':'justify-content-end'">
            <button class="btn btn-secondary px-2 py-1 fs-14px cancelBtn" *ngIf="raByUnitData.vehicleValidation?.unitNumber && raByUnitData?.contractSearchResults?.transNumber"
              (click)="onCancelRA()">Cancel</button>
            <span class="fw-medium blue-color d-flex align-items-center fs-14px" (click)="onCreateRA(manualRA)" [ngClass]="{'disable-text': (!manualRA.raNumber || !manualRA.signingCustomer) && !manualRA.customerPresent}">
              <span style="text-decoration: underline;cursor: pointer;">Create RA</span>
              <i class="pi pi-angle-right" style="font-size: 1.1rem"></i>
            </span>
          </div>
        </form>
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white w-md-45">
      <div>
        <div class="custom-styles">
          <h5><i class="floatLeft blue-color">Inspection Type</i></h5><br><br>
        </div>
        <div id="unit-buttons" class="d-flex align-items-center justify-content-center button-section-style">
          <button type="button" class="btn btn-xs btn-sm btn-md btn-custom fs-14px" [ngClass]="{'btn-yellow': inspectionTabs.checkout}"
          [disabled]="(!newRAObjectData?.unitNumber && newRAObjectData.customerPresent) || unitFoundNoRA"
            (click)="onCheckInOutClick('OUT')">Check-Out</button>
          <button type="button" class="btn btn-xs btn-sm btn-md btn-custom fs-14px" [ngClass]="{'btn-yellow': inspectionTabs.checkin}"
          [disabled]="(!newRAObjectData?.unitNumber && newRAObjectData.customerPresent) || unitFoundNoRA"
            (click)="onCheckInOutClick('IN')">Check-In</button>
          <button type="button" class="btn btn-xs btn-sm btn-md btn-custom fs-14px" [ngClass]="{'btn-yellow': inspectionTabs.noRA}"
          [disabled]="!newRAObjectData?.unitNumber" (click)="onClickNonRA()">Non-RA</button>
          <button type="button" class="btn btn-xs btn-sm btn-md btn-custom fs-14px" [ngClass]="{'btn-yellow': inspectionTabs.history}"
          [disabled]="!newRAObjectData?.unitNumber" (click)="goToHistory()">History</button>
        </div>
      </div>
    </div>

    <div *ngIf="showCheckout || showCheckIn" class="checkInOut-conatiner shadow p-3 mb-2 bg-white w-md-45" id='checkInNCheckOut'>
        <div class="shadow p-4 bg-white rounded">
          <div class="custom-styles">
            <h5><i class="floatLeft blue-color">{{showCheckout ? 'Check-Out Report' : 'Check-In Report'}}</i>
            </h5><br><br>
          </div>
          <div>
            <div class="custom-styles">
              <form id="checkInOutForm" [formGroup]="checkInOutForm">
                <div class="form-group form-check  marginBottom12P">
                  <input type="checkbox" class="form-check-input" formControlName="customerNotPresent"
                    id="customerNotPresent" (click)="onClickCustomerNotpresent()" >
                  <label class="form-check-label" for="customerNotPresent">Customer Not Present</label>
                </div>
                <div class="form-group p-float-label mt-2  marginBottom12P">
                  <input pInputText type="text" formControlName="signingCustomer" class="form-control marginBottom12P" id="signingCustomer">
                    <label class="required-input">Signing Driver</label>
                </div>
                <div class="form-group p-float-label  marginBottom12P">
                  <input pInputText type="email" formControlName="emailAddress" class="form-control" id="emailAddress">
                    <label>Email</label>
                </div>
                <div class="form-group p-float-label marginBottom12P">
                  <select pInputText formControlName="fuel" class="form-control" id="fuel">
                    <option *ngFor="let val of isVehicleTypeElectric ? fuelLevelObj.fuelLevelElectric : fuelLevelObj.fuelLevelNonElectric" [value]="val">{{val}}</option>
                  </select>
                  <label class="required-input">Fuel Level</label>
                </div>
                <div class="form-group p-float-label marginBottom12P" [ngClass]="{'d-inline-block col-6':showCheckIn}">
                  <input pInputText type="number" min="0" formControlName="mileageOut" class="form-control" id="mileageOut">
                  <label class="required-input">Odometer Out</label>
                </div>
                <div *ngIf="showCheckIn" class="form-group px-1 p-float-label marginBottom12P" [ngClass]="{'d-inline-block col-6':showCheckIn}">
                  <input pInputText type="number" min="0" formControlName="mileageIn" class="form-control" id="mileageIn">
                  <label class="required-input">Odometer In</label>
                </div>
                <div class="form-group col-6 p-float-label marginBottom12P" id="inline-block-el">
                  <input pInputText type="number" min="0" max="99" formControlName="furniturePads" class="form-control" id="furniturePads">
                  <label class="required-input">Furniture Pad</label>
                </div>
                <div class="form-group col-6 p-float-label px-1 marginBottom12P" id="inline-block-el">
                  <input pInputText type="number" min="0" max="9" formControlName="handtrucks" class="form-control" id="handtrucks">
                  <label class="required-input">Handtrucks</label>
                </div>
                <div class="form-group col-6 p-float-label marginBottom12P" id="inline-block-el" *ngIf="showCheckIn">
                  <input pInputText type="number" min="0" max="99" formControlName="furniturePadsReturned" class="form-control" id="furniturePadsReturned">
                  <label class="required-input">Furniture Pads Returned</label>
                </div>
                <div class="form-group col-6 p-float-label px-1 marginBottom12P" id="inline-block-el" *ngIf="showCheckIn">
                  <input pInputText type="number" min="0" max="9" formControlName="handtrucksReturned" class="form-control" id="handtrucksReturned">
                  <label class="required-input">Handtrucks Returned</label>
                </div>
                <div class="row errorBlock" *ngIf="isOutboundFPQty() || isOutboundHTQty() ">
                  <div class="col-6">
                    <div class="warningInputMsg" *ngIf="isOutboundFPQty()">
                      Less than outbound quantity
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="warningInputMsg" *ngIf="isOutboundHTQty()">
                      Less than outbound quantity
                    </div>
                  </div>
                </div>

                <div class="form-group col-6 p-float-label marginBottom12P" id="inline-block-el" *ngIf="raByUnitData?.vehicleValidation?.vehicleCategory !== 'TD' && raByUnitData?.vehicleValidation?.isReefer">
                  <input pInputText type="number" min="0" max="9999999" formControlName="reeferStandbyHrs" class="form-control" id="ReeferStandbyHrs">
                  <label>Reefer Standby Hrs</label>
                </div>
                <div class="form-group col-6 p-float-label marginBottom12P px-1" id="inline-block-el" *ngIf="raByUnitData?.vehicleValidation?.vehicleCategory !== 'TD' && raByUnitData?.vehicleValidation?.isReefer">
                  <input pInputText type="number" min="0" max="9999999" formControlName="reeferRunningHrs" class="form-control" id="ReeferRunningHrs">
                  <label>Reefer Running Hrs</label>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-yellow btn-xs btn-sm btn-md btn-width p-2 border-0"
                    [disabled]="checkInOutForm.invalid" (click)="beginInspection()">Begin Inspection</button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>

    <p-confirmDialog #ns class="lf-confirm-dialog" [visible]="showDuplicateReportDialog">
      <p-footer>
        <button class="btn btn-secondary pull-right btnCheckout margin-right-1P cancelBtn" (click)="ns.reject()">Cancel</button>
        <button class="btn btn-primary pull-right btnActive" (click)="ns.accept()">Continue</button>
      </p-footer>
    </p-confirmDialog>
  </div>
</div>

<app-loader *ngIf="showLoader"></app-loader>
