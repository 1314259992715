<header class="header custom-styles fixed-top">
  <div class="container d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center" (click)="goToHome()" [title]="version">
      <img src="assets/logo.svg" style="cursor: pointer;" alt="Logo" class="img-fluid me-2">
      <span class="fw-medium">Inspection App</span>
    </div>
    <div class="d-flex flex-column align-items-center height-21p">
      <a class="fw-medium" style="color: white;" data-bs-toggle="modal" data-bs-target="#logoutModal">
        <span class="d-none d-md-inline">{{userName}} :</span> Sign out
      </a>
      <div class="my-2"></div>
    </div>

  </div>
</header>

<!-- Logout Confirmation Modal -->
<div class="modal fade" id="logoutModal" tabindex="-1" aria-labelledby="logoutModalLabel">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="logoutModalLabel">Confirm Signout</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        Are you sure you want to Signout?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary cancelBtn" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" id="confirmLogout" (click)="logout()">Sign out</button>
      </div>
    </div>
  </div>
</div>
